import React, { useState, useEffect, useCallback } from 'react';
import Slider from 'react-slick';
import { Modal, Button, CloseButton } from 'react-bootstrap';
import ReactImageMagnify from 'react-image-magnify';
import { useVariablesGlobales } from '../globales/VariablesGlobales';
import {   BrowserRouter as Router, useHistory, useParams, useNavigate  } from 'react-router-dom';
import axios from 'axios';
import { use } from 'echarts';
import Swal from 'sweetalert2';
import { Modal as ModalRecompensas, Button as ButtonRecompensas } from 'react-bootstrap'; // Renombramos Modal y Button

axios.defaults.withCredentials = false;
axios.defaults.withXSRFToken = true;




export function HomeCanje() {
  return (
    <div>
      <h2>Inicio del sistema de Canje</h2>
      <p>Bienvenido a nuestra página de inicio.</p>
    </div>
  );
}
export function Catalogo() {
    return (
      <div>
        <h2>Catálogo de recompensas</h2>
        <p>Bienvenido a nuestra página de inicio.</p>
      </div>
    );
  }
export function Recompensa() {
    return (
        <div> 
            {/* Baner de Recompensas */}
            <div className="rbt-breadcrumb-default ptb--100 ptb_md--50 ptb_sm--30 bg-gradient-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-inner text-center">
                                <h2 className="title">Recompensas</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Banner amarillo */}
            <div className="rbt-call-to-action-area rbt-section-gap bg-gradient-8 pt--60 pb--80 mt--100" >
                    <div className="rbt-callto-action rbt-cta-default style-6">
                        <div className="container">
                            <div className="row g-5 align-items-center content-wrapper">
                                <div className="col-xxl-3 col-xl-3 col-lg-6">
                                    <div className="inner">
                                        <div className="content text-start">
                                            <h2 className="title color-white mb--0">Recompensas PLearning 2024</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-6">
                                    <div className="inner-content text-start">
                                        <p className="color-white">En tan solo unas semanas conocerás los regalos edición limitada que podrán ser tuyos
                                        </p>
                                    </div>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6">
                                    <div className="call-to-btn text-start text-xl-end">
                                    <div className="countdown-style-1 mt--50 justify-content-center">
                                        <div className="countdown justify-content-center" data-date="2024-05-13"><div className="countdown-container dias"><span className="countdown-value dias-bottom">21</span><span className="countdown-heading dias-top">Dias</span></div><div className="countdown-container horas"><span className="countdown-value horas-bottom">23</span><span className="countdown-heading horas-top">Hrs</span></div><div className="countdown-container minutos"><span className="countdown-value minutos-bottom">59</span><span className="countdown-heading minutos-top">Mins</span></div><div className="countdown-container segundos"><span className="countdown-value segundos-bottom">59</span><span className="countdown-heading segundos-top">Secs</span></div></div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            
            <div>
                {/* Recompensas con contador */}  
                <div className="rbt-shop-area rbt-section-overlayping-top rbt-section-gapBottom pt--120 pb--0">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rbt-default-card style-three rbt-hover">
                                    <div className="inner">
                                        <div className="thumbnail"><a href="single-product.html" style={{pointerEvents: 'none'}}><img src="assets/images/micrositio/PLe_RecompensaA_2024.png" alt="Histudy Book Image" /></a></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rbt-default-card style-three rbt-hover">
                                    <div className="inner">
                                        <div className="thumbnail"><a href="single-product.html" style={{pointerEvents: 'none'}}><img src="assets/images/micrositio/PLe_RecompensaB_2024.png" alt="Histudy Book Image" /></a></div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rbt-default-card style-three rbt-hover">
                                    <div className="inner">
                                        <div className="thumbnail"><a href="single-product.html" style={{pointerEvents: 'none'}}><img src="assets/images/micrositio/PLe_RecompensaC_2024.png" alt="Histudy Book Image" /></a></div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
    }

export function RecompensaNew() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    

        const {cuentaPL, setCuentaPL} = useVariablesGlobales();
        const [datosUsuario, setDatosUsuario] = useState(null);
        const [listadoProductos, setListadoProductos] = useState(null);
        const [datosCorte, setDatosCorte] = useState(null);
        const [creditosTotales, setCreditosTotales] = useState(null);
        const [creditosConsumidos, setCreditosConsumidos] = useState(null);
        const [creditosRestantes, setCreditosRestantes] = useState(null);
        const [puntajeTotal, setPuntajeTotal] = useState(null);
        const [datosCorteUsuario, setDatosCorteUsuario] = useState(null);
        const [mostrarCanjeo, setMostrarCanjeo] = useState(false);
        const [recargarDatos, setRecargarDatos] = useState(true);

        const [showPopup, setShowPopup] = useState(false);
        const [showPopupRecompensas, setShowPopupRecompensas] = useState(false);

        const handleClosePopup = () => {
            setShowPopup(false);
        };

        const handleShowPopup = () => {
            setShowPopup(true); // Muestra el modal cuando se haga clic en el enlace
        };

        // Función para cerrar el popup de recompensas
        const handleClosePopupRecompensas = () => {
            setShowPopupRecompensas(false);
            // Guardar la fecha y hora actuales en el localStorage
            localStorage.setItem('popupRecompensasClosedAt', new Date().getTime());
        };

        // Mostrar el modal si no se ha mostrado en las últimas 24 horas
        useEffect(() => {
            const lastClosed = localStorage.getItem('popupRecompensasClosedAt');
            const now = new Date().getTime();

            // Si no ha pasado más de 24 horas (86400000 ms), mostrar el modal
            if (!lastClosed || now - lastClosed > 24 * 60 * 60 * 1000) {
            setShowPopupRecompensas(true);
            }
        }, []);

        useEffect(() => {
            const token = JSON.parse(localStorage.getItem('authLaravel'));
            const id_local = JSON.parse(localStorage.getItem('idUsuario'));
            axios.get('https://plsystem.quarkservers2.com/api/canje_inicio', {
                params: {
                    id_usuario: id_local,
                    id_cuenta: cuentaPL,
                    prueba: 'si'
                }
            })
            .then(function (response) {
                console.log(response.data.corte);
                setDatosUsuario(response.data.usuario);
                setListadoProductos(response.data.productos);
                setDatosCorte(response.data.corte);
                setDatosCorteUsuario(response.data.corte_usuario);
                setPuntajeTotal(response.data.puntaje_total.toLocaleString());
                setCreditosTotales(response.data.creditos_totales);
                setCreditosConsumidos(response.data.creditos_consumidos);
                setCreditosRestantes(response.data.creditos_restantes);
                const popupRecompensas = localStorage.getItem('popupRecompensas');
                if (popupRecompensas) {
                    const closedTime = parseInt(popupRecompensas);
                    const currentTime = new Date().getTime();
                    if (currentTime - closedTime < 10 * 60 * 1000) {
                        setShowPopup(false); // Si han pasado menos de 10 minutos, no mostrar el modal
                    }
                    
                }
                
            })
            .catch(function (error) {
                // handle error
            });
        }, [recargarDatos]);

        

        return (
            <div> 
                {/* Baner de Recompensas */}
                <div className="rbt-breadcrumb-default ptb--100 ptb_md--50 ptb_sm--30 bg-gradient-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-center">
                                    <h2 className="title">Recompensas</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* Seccion de instrucciones */}
                <div className="rbt-counterup-area bg-color-extra2 rbt-section-gap pt--25 pb--60">
                    <div className="conter-style-2">
                        <div className="container">
                            {datosCorte ? (
                                <div className="row g-5 align-items-center">
                                <div className="col-lg-6">
                                    <div className="inner pl--50 pl_sm--0 pl_md--0">
                                        <div className="section-title text-start">
                                            <p className="description has-medium-font-size mt--20 mb--0"><strong>¡Panduit recompensa tu esfuerzo!</strong> Aquí podrás canjear todos los créditos que obtengas durante todo el programa por premios geniales. Sólo necesitas cubrir la cantidad de créditos del premio que deseas obtener, y ¡será tuyo! </p>
                                            <br></br>
                                            <a
                                                className="rbt-btn-link"
                                                style={{ fontSize: '20px', color: '#064a76', fontWeight: '700' }}
                                                href="#"
                                                onClick={handleShowPopup}
                                            >
                                                Cómo funciona <i className="feather-arrow-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row g-5">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 pt--30">
                                            <div className="rbt-counterup style-2">
                                                <div className="inner">
                                                    <div className="content text-end">
                                                        <h1 className=""><span className="odometer" data-count="800">{creditosRestantes != null ? creditosRestantes.toLocaleString() : ''}</span></h1>
                                                        <span className="subtitle">Créditos disponibles</span>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rbt-counterup style-2 mt--30">
                                                <div className="inner">
                                                    <div className="content ">
                                                        {/* <h1 className=""><span className="odometer" data-count="100">{creditosConsumidos}</span></h1>
                                                        <span className="subtitle">Créditos canjeados</span> */}
                                                        <div className="mb--20" style={{float:'right'}}>
                                                            <a href="/recompensas/pedidos" className="rbt-badge-2">
                                                                <div className="image">
                                                                    <img src="assets/images/micrositio/RPLe ICONO recompensas.png" />
                                                                </div> 
                                                                Ver mi <strong>historial de canjes</strong>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            ):(
                                <div className="row g-5 align-items-center">
                                <div className="col-lg-6">
                                    <div className="inner pl--50 pl_sm--0 pl_md--0">
                                        <div className="section-title text-start">
                                            <p className="description has-medium-font-size mt--20 mb--0"><strong>¡Panduit recompensa tu esfuerzo!</strong> Aquí podrás canjear todos los créditos que obtengas durante todo el programa por premios geniales. Sólo necesitas cubrir la cantidad de créditos del premio que deseas obtener, y ¡será tuyo! </p>
                                            <br></br>
                                            <a
                                                className="rbt-btn-link"
                                                style={{ fontSize: '20px', color: '#064a76', fontWeight: '700' }}
                                                href="#"
                                                onClick={handleShow}
                                            >
                                                Cómo funciona <i className="feather-arrow-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row g-5">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                                            <div className="rbt-counterup style-2">
                                                <div className="inner">
                                                    <div className="content text-end">
                                                        <h1 className=""><span className="odometer" data-count="800">{ puntajeTotal}</span></h1>
                                                        <span className="subtitle">Créditos acumulados</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )}
                            
                        </div>
                    </div>
                </div>
                
                <Modal show={showPopup} onHide={handleClosePopup} centered size="xl">
                    <Modal.Header style={{fontSize:'20px', color:'#000', fontWeight:'700'}}>
                        Recompensas
                        <CloseButton onClick={handleClosePopup} />
                    </Modal.Header>
                    <Modal.Body>
                        {/* Instrucciones */}
                        <div className="row row--15 mt_dec--30">
                            <div className="col-lg-3 col-xl-3 col-xxl-3 col-md-6 col-sm-6 col-12 mt--30">
                                <div className="service-card service-card-6">
                                    <div className="inner">
                                        <div className="icon">
                                            <img src="/assets/images/micrositio/PLe_Rewa-Ruta1_2024.png" alt="icons Images" />
                                        </div>
                                        <div className="content">
                                            <h6 className="title"><a href="#">Espera</a></h6>
                                            <p className="description">Por uno de los 2 periodos de canje</p>
                                        </div>
                                        <span className="number-text">1</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-xl-3 col-xxl-3 col-md-6 col-sm-6 col-12 mt--30">
                                <div className="service-card service-card-6">
                                    <div className="inner">
                                        <div className="icon">
                                            <img src="/assets/images/micrositio/PLe_Rewa-Ruta2_2024.png" alt="icons Images" />
                                        </div>
                                        <div className="content">
                                            <h6 className="title"><a href="#">Acumula</a></h6>
                                            <p className="description">Los créditos para para completar el canje</p>
                                        </div>
                                        <span className="number-text">2</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-xl-3 col-xxl-3 col-md-6 col-sm-6 col-12 mt--30">
                                <div className="service-card service-card-6">
                                    <div className="inner">
                                        <div className="icon">
                                            <img src="/assets/images/micrositio/PLe_Rewa-Ruta3_2024.png" alt="Shape Images" />
                                        </div>
                                        <div className="content">
                                            <h6 className="title"><a href="#">Selecciona</a></h6>
                                            <p className="description">Alguno de los 3 regalos de edición</p>
                                        </div>
                                        <span className="number-text">3</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-xl-3 col-xxl-3 col-md-6 col-sm-6 col-12 mt--30">
                                <div className="service-card service-card-6">
                                    <div className="inner">
                                        <div className="icon">
                                            <img src="/assets/images/micrositio/PLe_Rewa-Ruta4_2024.png" alt="Shape Images" />
                                        </div>
                                        <div className="content">
                                            <h6 className="title"><a href="#">Llena</a></h6>
                                            <p className="description">Tus datos y espera tu paquete</p>
                                        </div>
                                        <span className="number-text">4</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <ModalRecompensas 
                    className="popup-recompensa" 
                    show={showPopupRecompensas} 
                    onHide={handleClosePopupRecompensas} 
                    backdrop="static" 
                    keyboard={false}
                    centered // Centrado vertical
                    dialogClassName="modal-lg" // Tamaño grande
                >
                    <ModalRecompensas.Header style={{ background: '#fff', textAlign: 'center', borderBottom: 'none' }}>
                    <button type="button" className="btn-close" aria-label="Close" style={{ fontSize: '1rem', padding: '0.5rem' }} onClick={handleClosePopupRecompensas}></button>
                    </ModalRecompensas.Header>
                    <ModalRecompensas.Body>
                    <div>
                        <img src="/assets/images/micrositio/PL3_PopUp-rECOMPENSASV1_oct24.png" alt="Descripción de la imagen" style={{ maxWidth: '100%', height: 'auto' }} />
                    </div>
                    </ModalRecompensas.Body>
                    <ModalRecompensas.Footer>
                    <ButtonRecompensas className='rbt-btn btn-gradient icon-hover' onClick={handleClosePopupRecompensas}>Aceptar</ButtonRecompensas>
                    </ModalRecompensas.Footer>
                </ModalRecompensas>
    
                {/* Banner amarillo */}
                <div className="rbt-call-to-action-area rbt-section-gap pt--60 pb--80" >
                    <div className="rbt-callto-action rbt-cta-default style-6">
                        <div className="container">
                        </div>
                    </div>
                </div>
                
                <div>
                    {/* Recompensas con contador */}  
                    <div className="rbt-shop-area rbt-section-overlayping-top rbt-section-gapBottom pt--50 pb--0">
                        <div className="container">
                            <div className="row g-5">
                            {listadoProductos && listadoProductos.map((producto, index) => {
                                return (
                                    <div className="col-lg-4 col-md-6 col-12" key={index}>
                                        <div className="rbt-default-card style-three rbt-hover">
                                            <div className="inner">
                                                <div className="thumbnail"><a href={`/recompensas/producto/${producto.id}`} style={{pointerEvents: 'none'}}><img src={`https://plsystem.quarkservers2.com/img/publicaciones/${producto.imagen}`} alt="Histudy Book Image" /></a></div>
                                                <h4 className="title mt--10 mb--10">{producto.nombre}</h4>
                                                <p className="mt--20">
                                                {producto.descripcion}
                                                </p>
                                                <div className="rbt-price justify-content-start mt--10">
                                                    <span className="current-price theme-gradient" style={{margin:'0px 0px 15px'}}>{producto.creditos?.toLocaleString()} Créditos</span>
                                                </div>
                                                <div className="product-action mb--20">
                                                    <div className="addto-cart-btn">
                                                        <a className="rbt-btn btn-gradient hover-icon-reverse" href={`/recompensas/producto/${producto.id}`}>
                                                            <span className="icon-reverse-wrapper">
                                                                <span className="btn-text">Ver detalle</span>
                                                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rbt-rbt-blog-area rbt-section-gapTop bg-color-white pt--0 mt--60">
                    <div className="container">
                        <div className="row g-5">
                            <div className="section-title text-center mb--0">
                                <h2 className="title">Condiciones</h2>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div className="rbt-list-style-area bg-color-white rbt-section-gap" style={{paddingTop:'0px', paddingBottom: '60px'}}>
                    <div className="container">
                        <div className="row g-5 pt--30">
                            <div className="col-lg-6">
                                <div className="rbt-feature-wrapper">
                                    <div className="rbt-feature feature-style-2">
                                        <div className="icon bg-icon-opacity-4">
                                            <i className="feather-user-check"></i>
                                        </div>
                                        <div className="feature-content">
                                            <h6 className="feature-title">Plan Usuario</h6>
                                            <p className="feature-description">Sólo pueden participar usuarios de nivel Medio y Completo.</p>
                                        </div>
                                    </div>
                                    <div className="rbt-feature feature-style-2">
                                        <div className="icon bg-icon-opacity-5">
                                            <i className="feather-check-circle"></i>
                                        </div>
                                        <div className="feature-content">
                                            <h6 className="feature-title">Puntos y Créditos</h6>
                                            <p className="feature-description">Cada vez que obtengas puntos en el programa, obtendrás la misma cantidad de créditos; fuera de su obtención, ambas cantidades son independientes.</p>
                                        </div>
                                    </div>
                                    <div className="rbt-feature feature-style-2">
                                        <div className="icon bg-icon-opacity-4">
                                            <i className="feather-lock"></i>
                                        </div>
                                        <div className="feature-content">
                                            <h6 className="feature-title">Créditos Intransferibles</h6>
                                            <p className="feature-description">Los créditos son intransferibles entre usuarios.</p>
                                        </div>
                                    </div>
                                    <div className="rbt-feature feature-style-2">
                                        <div className="icon bg-icon-opacity-5">
                                            <i className="feather-check"></i>
                                        </div>
                                        <div className="feature-content">
                                            <h6 className="feature-title">Artículos Permitidos</h6>
                                            <p className="feature-description">Puedes pedir más de 1 artículo de cada recompensa, siempre que tu total de créditos permita el canje.</p>
                                        </div>
                                    </div>
                                    <div className="rbt-feature feature-style-2">
                                        <div className="icon bg-icon-opacity-4">
                                            <i className="feather-calendar"></i>
                                        </div>
                                        <div className="feature-content">
                                            <h6 className="feature-title">Periodo Canje</h6>
                                            <p className="feature-description">Los periodos comprendidos para el canje de puntos son del 16 al 27 de septiembre y 2 de octubre al 8 de noviembre</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="rbt-feature-wrapper" style={{paddingTop: '50px'}}>
                                    <div className="rbt-feature feature-style-2">
                                        <div className="icon bg-icon-opacity-5">
                                            <i className="feather-calendar"></i>
                                        </div>
                                        <div className="feature-content">
                                            <h6 className="feature-title">Ventanas de canje</h6>
                                            <p className="feature-description">Fuera de los periodos de canje no podrán canjearse créditos por recompensas.</p>
                                        </div>
                                    </div>
                                    <div className="rbt-feature feature-style-2">
                                        <div className="icon bg-icon-opacity-4">
                                            <i className="feather-x"></i>
                                        </div>
                                        <div className="feature-content">
                                            <h6 className="feature-title">Sin Cambios</h6>
                                            <p className="feature-description">Una vez efectuado el canje de puntos por una recompensa, no hay cambios de talla; asegúrate de elegir la correcta.</p>
                                        </div>
                                    </div>
                                    <div className="rbt-feature feature-style-2">
                                        <div className="icon bg-icon-opacity-5">
                                            <i className="feather-truck"></i>
                                        </div>
                                        <div className="feature-content">
                                            <h6 className="feature-title">Envíos</h6>
                                            <p className="feature-description">Los envíos de recompensas pueden tomar de 2-3 semanas.</p>
                                        </div>
                                    </div>
                                    <div className="rbt-feature feature-style-2">
                                        <div className="icon bg-icon-opacity-4">
                                            <i className="feather-alert-triangle"></i>
                                        </div>
                                        <div className="feature-content">
                                            <h6 className="feature-title">Vigencia de créditos</h6>
                                            <p className="feature-description">Los créditos que no se utilicen no son acumulables para temporadas futuras de P-Learning; haz los canjes tan pronto como puedas</p>
                                        </div>
                                    </div>
                                    <div className="rbt-feature feature-style-2">
                                        <div className="icon bg-icon-opacity-4">
                                            <i className="feather-shopping-bag"></i>
                                        </div>
                                        <div className="feature-content">
                                            <h6 className="feature-title">Variación de productos</h6>
                                            <p className="feature-description">Las prendas pueden tener variaciones entre las fotos y los regalos realizados en la región México y Latinoamérica</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
        );
}


export function  RecompensaProduct() {
    const history = useHistory(); // Hook para la navegación
    const {cuentaPL, setCuentaPL} = useVariablesGlobales();
    const { id } = useParams();
        const [datosUsuario, setDatosUsuario] = useState(null);
        const [listadoProductos, setListadoProductos] = useState(null);
        const [datosProducto, setDatosProducto] = useState(null);
        const [variacionesProducto, setVariacionesProducto] = useState(null);
        const [variacionesCantidad, setVariacionesCantidad] = useState(null);
        const [variacionesCanjeados, setVariacionesCanjeados] = useState(null);
        const [datosCorte, setDatosCorte] = useState(null);
        const [creditosTotales, setCreditosTotales] = useState(null);
        const [creditosConsumidos, setCreditosConsumidos] = useState(null);
        const [creditosRestantes, setCreditosRestantes] = useState(null);
        const [maximoCanjeable, setMaximoCanjeable] = useState(null);
        const [datosCorteUsuario, setDatosCorteUsuario] = useState(null);
        const [mostrarCanjeo, setMostrarCanjeo] = useState(false);
        const [recargarDatos, setRecargarDatos] = useState(true);

        const [quantity, setQuantity] = useState(1);
        const [price, setPrice] = useState(30000);
        const [totalPrice, setTotalPrice] = useState(price);
        const [images, setImages] = useState([]);
        const [selectedImage, setSelectedImage] = useState(null);
        const [mostrarCarrusel, setMostrarCarrusel] = useState(false);

         // Variables para enviar
         const getInitialCarrito = () => {
            const carritoGuardado = localStorage.getItem('carrito');
            return carritoGuardado ? JSON.parse(carritoGuardado) : [];
          };

        // Variables para el carrito
        const [variacionSeleccionada, setVariacionSeleccionada] = useState('');
        const [cantidad, setCantidad] = useState(1);
        const [carrito, setCarrito] = useState(getInitialCarrito);
        const [totalCreditos, setTotalCreditos] = useState(() => {
            // Calcula el total inicial desde localStorage o devuelve 0 si no existe
            const savedTotal = localStorage.getItem('totalCreditos');
            return savedTotal ? parseFloat(savedTotal) : 0;
        });
        
        // Canje de producto botón
        const handleClickCanjear = (event) => {
                event.preventDefault(); // Evitar la redirección del enlace

                const maxQuantity = calcularCantidadDisponible(variacionSeleccionada);
            
                // Crear el objeto del producto a agregar
                const producto = {
                id: datosProducto.id,
                nombre: datosProducto.nombre,
                variaciones: variacionesProducto,
                variacion: variacionSeleccionada,
                cantidad: cantidad,
                cantidad_max: maxQuantity,
                creditos_unidad: datosProducto.creditos,
                creditos_totales: datosProducto.creditos * cantidad,
                };
            
                // Verificar si el producto ya está en el carrito con el mismo id y variación
                const productoExistente = carrito.find(
                (item) => item.id === producto.id && item.variacion === producto.variacion
                );
            
                let carritoActualizado;
            
                if (productoExistente) {
                // Si el producto ya existe, aumentar la cantidad y actualizar los créditos totales
                carritoActualizado = carrito.map((item) => {
                    if (item.id === producto.id && item.variacion === producto.variacion) {
                    const nuevaCantidad = item.cantidad + cantidad;
                    return {
                        ...item,
                        cantidad: nuevaCantidad,
                        creditos_totales: nuevaCantidad * item.creditos_unidad,
                    };
                    }
                    return item;
                });
                } else {
                // Si no existe, agregar el producto al carrito
                carritoActualizado = [...carrito, producto];
                }
            
                // Calcular el nuevo total de créditos ocupados por todo el carrito
                const nuevoTotalCreditos = carritoActualizado.reduce((total, item) => {
                return total + item.creditos_totales;
                }, 0);
                
                // Verificar si los créditos restantes permiten agregar esta nueva cantidad
                if (nuevoTotalCreditos > creditosRestantes) {
                    Swal.fire({
                        title: 'No cuentas con suficientes créditos',
                        icon: 'warning',
                        confirmButtonColor: '#213746',
                        confirmButtonText: 'Aceptar'
                    });
                } else {
                    // Actualizar el estado del carrito y el total de créditos
                    setCarrito(carritoActualizado);
                    setTotalCreditos(nuevoTotalCreditos);
                    // Guardar el carrito y el total de créditos en localStorage
                    localStorage.setItem('carrito', JSON.stringify(carritoActualizado));
                    localStorage.setItem('totalCreditos', nuevoTotalCreditos);
                }
        };
        
        
        
        const [showModal, setShowModal] = useState(false);
        const [isTransitioning, setIsTransitioning] = useState(true);
        const [fadeClass, setFadeClass] = useState('');

        // Canje inicio API
        useEffect(() => {
            const token = JSON.parse(localStorage.getItem('authLaravel'));
            const id_local = JSON.parse(localStorage.getItem('idUsuario'));
            axios.get('https://plsystem.quarkservers2.com/api/canje_inicio', {
                params: {
                    id_usuario: id_local,
                    id_cuenta: cuentaPL,
                    prueba : 'no'
                }
            })
            .then(function (response) {
                setDatosUsuario(response.data.usuario);
                setListadoProductos(response.data.productos);
                setDatosCorte(response.data.corte);
                setDatosCorteUsuario(response.data.corte_usuario);
                setCreditosTotales(response.data.creditos_totales);
                setCreditosConsumidos(response.data.creditos_consumidos);
                setCreditosRestantes(response.data.creditos_restantes);
                // Detalles del producto
            
                
            })
            .catch(function (error) {
                // handle error
            });
            
        }, [recargarDatos]);

        // datos del producto
        useEffect(() => {
            axios.get('https://plsystem.quarkservers2.com/api/detalles_producto', {
                params: {
                    id: id
                }
            })
            .then(function (response) {
                console.log(response.data.producto.creditos);
                console.log(creditosRestantes);
                const max_canjeable = creditosRestantes/response.data.producto.creditos;
                setMaximoCanjeable(max_canjeable);
                setVariacionesCanjeados(response.data.canjeados);
                setDatosProducto(response.data.producto);
                setVariacionesProducto(JSON.parse(response.data.producto.variaciones));
                setVariacionesCantidad(JSON.parse(response.data.producto.variaciones_cantidad));
                const galeria = response.data.galeria;

                // Definir la URL base
                const baseUrl = 'https://plsystem.quarkservers2.com/img/publicaciones/';

                // Extraer los datos de "imagen", concatenar la URL base, y crear un nuevo array
                const imagenArray = galeria.map((item) => baseUrl + item.imagen);

                const imagenArrayConProducto = [
                    baseUrl + response.data.producto.imagen,  // Concatenando la nueva imagen
                    ...imagenArray,  // Usando spread operator para copiar las imágenes existentes
                    
                ];

                // Actualizar el estado con el array de imágenes completas
                setImages(imagenArrayConProducto);
                setSelectedImage(images[0]);

            })
            .catch(function (error) {
                // handle error
            });
        },[datosCorteUsuario]);

        // Carrusel del producto
        useEffect(() => {
            // Verifica que las imágenes estén cargadas y haya una imagen seleccionada
            if (images.length > 0  && selectedImage !== undefined && selectedImage !== null) {
              setMostrarCarrusel(true);
            } else {
              setMostrarCarrusel(false);
            }
          }, [images, selectedImage]); // Observa cambios en ambas variables
    
        
        // Cambiar imágen del producto
        useEffect(() => {
            let interval;
            if (isTransitioning) {
                interval = setInterval(() => {
                    setSelectedImage((prevImage) => {
                        const currentIndex = images.indexOf(prevImage);
                        const nextIndex = (currentIndex + 1) % images.length;
                        return images[nextIndex];
                    });
                }, 2000);
            }
            return () => clearInterval(interval);
        }, [images, isTransitioning, selectedImage]);
        
        // Abrir Zoom al producto
        const handleModalOpen = (image) => {
            setSelectedImage(image);
            setIsTransitioning(false);
            setShowModal(true);
        };
        
        // Cerrar Zoom al producto
        const handleModalClose = () => {
            setShowModal(false);
            setIsTransitioning(true);
        };
        
        // Settings de la galería
        const settings = {
            dots: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000
        };
        
        // Botones del formulario de producto

        // Aumentar cantidad
        const incrementQuantity = () => {
            if (variacionSeleccionada) {
                const maxQuantity = calcularCantidadDisponible(variacionSeleccionada);
                if (quantity < maxQuantity) {
                setQuantity(quantity + 1);
                setCantidad(quantity + 1);
                }
            }
        };
        
        // disminuir cantidad
        const decrementQuantity = () => {
            if (quantity > 1) {
                setQuantity(quantity - 1);
                setCantidad(quantity - 1);
            }
        };

        // Borrar producto Carrito
        const handleEliminar = (index) => {
            // Filtrar el carrito para eliminar el producto en el índice dado
            const carritoActualizado = carrito.filter((_, i) => i !== index);
            setCarrito(carritoActualizado);
        
            // Calcular el nuevo total de créditos después de la eliminación
            const nuevoTotalCreditos = carritoActualizado.reduce((total, item) => {
              return total + item.creditos_totales;
            }, 0);
        
            // Actualizar el estado del total de créditos
            setTotalCreditos(nuevoTotalCreditos);
        
            // Actualizar el carrito y el total de créditos en localStorage
            localStorage.setItem('carrito', JSON.stringify(carritoActualizado));
            localStorage.setItem('totalCreditos', nuevoTotalCreditos);
          };

        // Seleccionar variación
        const handleCheckboxChange = (event) => {
            const seleccion = event.target.value;
            setVariacionSeleccionada(seleccion);
            setQuantity(1); // Resetear cantidad a 1 al seleccionar una nueva variación
            setCantidad(1);
          };

          // Función para manejar el cambio de variación (si es necesario)
          const handleChangeVariacion = (index, nuevaVariacion) => {
            const nuevoCarrito = [...carrito];

            if (nuevoCarrito[index].variacion === nuevaVariacion) {
                nuevoCarrito[index].variacion = ''; // Deselecciona la talla
            } else {
                // Si es una talla diferente, selecciona la nueva talla
                nuevoCarrito[index].variacion = nuevaVariacion;
            }

            setCarrito(nuevoCarrito); 
            localStorage.setItem('carrito', JSON.stringify(nuevoCarrito));
        };
        
        // Función para actualizar la cantidad de productos
        const actualizarCantidad = (index, nuevaCantidad) => {
            const nuevoCarrito = [...carrito];
            const producto = nuevoCarrito[index];
            const creditos_totales = nuevaCantidad * producto.creditos_unidad;
        
            // Calcular el total de créditos del carrito si se actualiza la cantidad
            const nuevoTotalCreditos = nuevoCarrito.reduce((total, item, i) => {
                if (i === index) {
                    return total + creditos_totales;
                }
                return total + item.creditos_totales;
            }, 0);
        
            if (nuevoTotalCreditos > creditosRestantes) {
                Swal.fire({
                    title: 'No cuentas con suficientes créditos',
                    icon: 'warning',
                    confirmButtonColor: '#213746',
                    confirmButtonText: 'Aceptar'
                });
            } else {
                // Actualizar la cantidad en el carrito y los créditos totales
                nuevoCarrito[index].cantidad = parseInt(nuevaCantidad, 10);
                nuevoCarrito[index].creditos_totales = creditos_totales;
                setCarrito(nuevoCarrito); 
                setTotalCreditos(nuevoTotalCreditos);
                localStorage.setItem('carrito', JSON.stringify(nuevoCarrito));
                localStorage.setItem('totalCreditos', nuevoTotalCreditos);
            }
        };
        

          // Calcular la cantidad disponible
            const calcularCantidadDisponible = (variacion) => {
                // Obtener la cantidad disponible para la variación seleccionada
                const index = variacionesProducto.indexOf(variacion);
                const cantidadDisponible = variacionesCantidad[index];
                const cantidadMaximaCanje = maximoCanjeable;
            
                // Filtrar las canjeos que coincidan con la variación actual
                const canjeosParaVariacion = variacionesCanjeados.filter(
                (canjeo) => canjeo.variacion === variacion
                );
            
                // Sumar las cantidades canjeadas para la variación actual
                const totalCanjeados = canjeosParaVariacion.reduce(
                (sum, canjeo) => sum + canjeo.cantidad,
                0
                );
            
                // Calcular la cantidad restante disponible después de restar las canjeadas
                let calculado = cantidadDisponible - totalCanjeados;
            
                // Asegurarse de que el resultado no exceda el máximo canjeable
                calculado = Math.min(calculado, cantidadMaximaCanje);
            
                // Devolver el valor redondeado hacia abajo para asegurarse de que sea un entero
                return Math.floor(calculado);
            };

            const generarOpciones = (maximo) => {
                return Array.from({ length: maximo }, (_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                ));
              };


        if(datosProducto){   
        return (
            <div>
                <div className="rbt-breadcrumb-default ptb--100 ptb_md--50 ptb_sm--30 bg-gradient-1">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-inner text-center">
                        <h2 className="title">Recompensas</h2>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
        
                <div className="rbt-single-product-area rbt-single-product rbt-section-gap pt--30 pb--50 mb--100">
                    <div className="container">
                        <div className="row g-5 row--30 align-items-center justify-content-center">
                            <div className="col-lg-4">
                            {mostrarCarrusel ? (
                                <div className="gallery-container">
                                    <div className="main-image-container">
                                        <img 
                                            src={selectedImage} 
                                            alt="Imagen principal" 
                                            className={`img-fluid main-image ${fadeClass}`}
                                            onClick={() => handleModalOpen(selectedImage)}
                                        />
                                    </div>
                                    <div className="thumbnailb-container">
                                        {images.map((img, index) => (
                                            <img
                                                key={index}
                                                src={img}
                                                alt={`Thumbnailb ${index + 1}`}
                                                onClick={() => setSelectedImage(img)}
                                                className={`thumbnailb ${selectedImage === img ? 'selected' : ''}`}
                                                style={{ cursor: 'pointer', margin: '5px', border: selectedImage === img ? '2px solid #000' : 'none' }}
                                            />
                                        ))}
                                    </div>
                                </div>
                                ):(
                                    <div  style={{textAlign:'center'}}><p>Cargando...</p></div>
                                )}

                                {/* Modal */}
                                <Modal show={showModal} onHide={handleModalClose} centered dialogClassName="custom-modal" size="lg">
                                    <div className="modal-header custom-modal-header">
                                        <button type="button" className="btn-close custom-close-button" onClick={handleModalClose}></button>
                                    </div>
                                    <Modal.Body>
                                        <img 
                                            src={selectedImage}
                                            alt="Imagen seleccionada" 
                                            className="img-fluid modal-image fade-in"
                                        />
                                    </Modal.Body>
                                </Modal>
                            </div>

                            <div className="col-lg-4 mt--0">
                                <div className="content">
                                <h2 className="title mt--10 mb--10">{datosProducto.nombre}</h2>
                                <div className="rbt-price justify-content-start mt--10">
                                    <span className="current-price theme-gradient">{datosProducto.creditos?.toLocaleString()} Créditos</span>
                                </div>
                                <div className="mt--20 pb--20" dangerouslySetInnerHTML={{ __html: datosProducto.contenido }}>
                                </div>
                                
                                {datosCorte && creditosRestantes >= datosProducto.creditos ? (
                                    <div>
                                        <div className="rbt-sidebar-list-wrapper rbt-tag-list mb-3">
                                            {variacionesProducto
                                                .filter((variacion) => variacion !== null) // Filtrar los valores nulos
                                                .map((variacion, index) => {
                                                    // Obtener la cantidad correspondiente para la variación actual
                                                    const cantidadDisponible = variacionesCantidad[index]; // Suponiendo que la cantidad disponible es la misma que la del índice de la variación

                                                    // Filtrar los canjeos que coincidan con la variación actual
                                                    const canjeosParaVariacion = variacionesCanjeados.filter(
                                                        (canjeo) => canjeo.variacion === variacion
                                                    );

                                                    // Sumar las cantidades canjeadas para la variación actual
                                                    const totalCanjeados = canjeosParaVariacion.reduce(
                                                        (sum, canjeo) => sum + canjeo.cantidad,
                                                        0
                                                    );

                                                    // Calcular la cantidad restante disponible después de restar las canjeadas
                                                    const cantidadRestante = cantidadDisponible - totalCanjeados;

                                                    return (
                                                        <label key={index} className="checkbox-label">
                                                            <input
                                                                type="radio" // Cambiar de checkbox a radio
                                                                name="variacion"
                                                                value={variacion}
                                                                checked={variacionSeleccionada === variacion}
                                                                onChange={handleCheckboxChange}
                                                            />
                                                            <span className="checkbox-text">
                                                                {variacion}  {/* Mostrar la cantidad restante */}
                                                            </span>
                                                        </label>
                                                    );
                                                })}
                                        </div>
                                        <div className="product-action mb--20 mt--20">
                                            <div className="pro-qty">
                                                <span onClick={decrementQuantity} className="dec qtybtn">-</span>
                                                <input type="text" value={quantity} readOnly />
                                                <span onClick={incrementQuantity} className="inc qtybtn">+</span>
                                            </div>
                                            <div className="addto-cart-btn">
                                                {datosCorte && variacionSeleccionada !== '' ? (
                                                    <a className="rbt-btn btn-gradient hover-icon-reverse" href="/checkout" onClick={handleClickCanjear}>
                                                        <span className="icon-reverse-wrapper">
                                                            <span className="btn-text">Canjear</span>
                                                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                        </span>
                                                    </a>
                                                ) : (
                                                    <button className="rbt-btn btn-sm ml--20" type="submit" disabled>Selecciona una talla</button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ) : null}

                                {/* Verificar si los créditos restantes son menores */}
                                {creditosRestantes < datosProducto.creditos && datosCorte && (
                                    <h5 className="badge bg-danger mb-3">No tienes suficientes créditos</h5>
                                )}

                                {/* Mostrar mensaje si no hay datos de corte */}
                                {!datosCorte && (
                                    <>
                                        <button className="rbt-btn btn-sm ml--0" type="submit" disabled>Próximamente</button> <br />
                                        <p className='pt--20 d-none'><em>Próxima ventana de canje: 16 al 27 de Septiembre</em></p>
                                    </>
                                )}
                                
                                
                                
                            </div>
                            </div>

                            <div className={`col-lg-3 ${!datosCorte ? 'd-none' : ''}`}>
                                <h5 style={{ fontWeight: '300', textAlign:'center' }}>CRÉDITOS DISPONIBLES: <strong>
                                {creditosRestantes != null ? creditosRestantes.toLocaleString() : ''}</strong></h5>
                                <div className="mb--20" style={{ marginBottom: '20px', textAlign:'center' }}>
                                    <a href="/recompensas/pedidos" className="rbt-badge-2" style={{ display: 'inline-block', textAlign: 'center' }}>
                                        <div className="image" style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '10px' }}>
                                            <img src="/assets/images/micrositio/RPLe ICONO recompensas.png" style={{ width: '30px', height: 'auto' }} />
                                        </div>
                                        <span style={{ verticalAlign: 'middle' }}>
                                            Ver mi <strong>historial de canjes</strong>
                                        </span>
                                    </a>
                                </div>
                                <div className="checkout-cart-total text-center">
                                    <h4>TU CANJEO</h4>
                                    <hr />
                                    {carrito.length > 0 ? (
                                        carrito.map((producto, index) => (
                                        <div key={index} style={{ paddingBottom: '15px', marginBottom: '15px', borderBottom: '1px solid #ddd', textAlign:'left' }}>
                                            
                                            
                                            <span style={{ fontSize: '18px', color: '#000', textAlign:'left' }}>{producto.nombre}</span> 
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span style={{ fontSize: '18px', color: '#000', fontWeight: '600', marginRight: '10px' }}>{producto.creditos_totales.toLocaleString()} créditos</span>
                                                    <button onClick={() => handleEliminar(index)} style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}>
                                                    <i className="feather-trash-2" style={{ fontSize: '20px', color: '#d9534f' }}></i>
                                                    </button>
                                                </div>
                                            </div>


                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '10px' }}>
                                            <select
                                                style={{ height: 'auto', width: '150px', padding: '10px', marginBottom: '10px', fontSize: '14px' }}
                                                value={producto.variacion || ''}  // Usa '' como valor predeterminado si no hay una talla seleccionada
                                                onChange={(e) => handleChangeVariacion(index, e.target.value)}
                                            >
                                                <option value="" disabled>
                                                    Selecciona una talla
                                                </option>
                                                {producto.variaciones
                                                    .filter((talla) => talla !== null)  // Filtra las tallas que no sean null
                                                    .map((talla, i) => (
                                                    <option 
                                                        key={i}
                                                        value={talla}
                                                    >
                                                        Talla: {talla}
                                                    </option>
                                                ))}
                                            </select>

                                            <select
                                                style={{ height: 'auto', width: '150px', padding: '10px', fontSize: '14px' }}
                                                value={producto.cantidad}
                                                onChange={(e) => {
                                                    const nuevaCantidad = parseInt(e.target.value, 10);
                                                    const totalCreditosRequeridos = nuevaCantidad * producto.creditos_unidad;
                                                    if (totalCreditosRequeridos > creditosRestantes) {
                                                    Swal.fire({
                                                        title: 'No cuentas con suficientes créditos',
                                                        icon: 'warning',
                                                        confirmButtonColor: '#213746',
                                                        confirmButtonText: 'Aceptar'
                                                    });
                                                    } else {
                                                    actualizarCantidad(index, nuevaCantidad);
                                                    }
                                                }}
                                                >
                                                    {generarOpciones(calcularCantidadDisponible(producto.variacion))}
                                               
                                            </select>

                                            </div>
                                        </div>
                                        ))
                                    ) : (
                                        <div>
                                        <p style={{textTransform:'uppercase'}}>No hay productos en el carrito.</p>
                                        </div>
                                    )}

                                    <h5 className="mt-30 pt--10">Total <span>{totalCreditos.toLocaleString()} Créditos</span></h5>
                                    {creditosRestantes >= totalCreditos && carrito?.length > 0 && (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width:'300px', gap:'20px'}}>
                                        {/* Botón de Volver a la izquierda */}
                                        <a
                                        type="button"
                                        className="rbt-btn btn-gradient btn-gradient-4 hover-icon-reverse pt--0"
                                        href="/recompensas"
                                        style={{ flex: '0 0 auto', padding: '10px 20px' }}
                                        >
                                        Volver
                                        </a>

                                        {/* Botón de Agregar dirección a la derecha */}
                                        <a
                                        href="/checkout"
                                        type="submit"
                                        className="rbt-btn btn-gradient w-100 hover-icon-reverse pt--0"
                                        style={{ flex: '1', padding: '10px 20px' }}
                                        >
                                        <span className="icon-reverse-wrapper">
                                            <span className="btn-text">Agregar</span>
                                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                        </span>
                                        </a>
                                    </div>
                                    )}

                                    {creditosRestantes < totalCreditos && (
                                        <h5 className="badge bg-danger mb-3">No tienes suficientes créditos</h5>
                                    )}
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* Recompensas con contador */}  
                <div className="rbt-shop-area rbt-section-overlayping-top rbt-section-gapBottom pt--50 pb--0">
                        <div className="container">
                            <div className="row g-5 justify-content-center text-center">
                                <h3>Otros productos</h3>
                            {listadoProductos && listadoProductos.map((producto, index) => {
                                if(id!=producto.id){
                                    return (
                                        <div className="col-lg-3 col-md-4 col-12" key={index}>
                                            <div className="rbt-default-card style-three rbt-hover">
                                                <div className="inner">
                                                    <div className="thumbnail"><a href={`/recompensas/producto/${producto.id}`} style={{pointerEvents: 'none'}}><img src={`https://plsystem.quarkservers2.com/img/publicaciones/${producto.imagen}`} alt="Histudy Book Image" /></a></div>
                                                    <h5 className="title mt--10 mb--10">{producto.nombre}</h5>
                                                    <div className="rbt-price mt--10 text-center">
                                                        <span className="current-price theme-gradient" style={{margin:'0px auto 15px auto', fontSize:'20px'}}>{producto.creditos?.toLocaleString()} Créditos</span>
                                                    </div>
                                                    <div className="product-action mb--20">
                                                        <div className="addto-cart-btn">
                                                            <a className="rbt-btn btn-gradient hover-icon-reverse" href={`/recompensas/producto/${producto.id}`}>
                                                                <span className="icon-reverse-wrapper">
                                                                    <span className="btn-text">Ver detalle</span>
                                                                <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                                <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                                
                            })}
                            </div>
                        </div>
                    </div>
            </div>
            
        );
    }
}

export function Checkout() {
    const history = useHistory();
    
    const getInitialCarrito = () => {
        // Recupera el carrito inicial desde localStorage o devuelve un array vacío si no existe
        const savedCarrito = localStorage.getItem('carrito');
        return savedCarrito ? JSON.parse(savedCarrito) : [];
      };

    const {cuentaPL, setCuentaPL} = useVariablesGlobales();
    const { id } = useParams();
    const [datosUsuario, setDatosUsuario] = useState(null);

    const [carrito, setCarrito] = useState(getInitialCarrito);
    const [totalCreditos, setTotalCreditos] = useState(() => {
        // Calcula el total inicial desde localStorage o devuelve 0 si no existe
        const savedTotal = localStorage.getItem('totalCreditos');
        return savedTotal ? parseFloat(savedTotal) : 0;
    });
    const [listadoProductos, setListadoProductos] = useState(null);
    const [datosCorte, setDatosCorte] = useState(null);
    const [creditosTotales, setCreditosTotales] = useState(null);
    const [creditosConsumidos, setCreditosConsumidos] = useState(null);
    const [creditosRestantes, setCreditosRestantes] = useState(null);
    const [datosCorteUsuario, setDatosCorteUsuario] = useState(null);
    const [mostrarCanjeo, setMostrarCanjeo] = useState(false);
    const [recargarDatos, setRecargarDatos] = useState(true);

    // Variables para el formulario 
    const [nombreCompleto, setNombreCompleto] = useState('');
    const [calle, setCalle] = useState('');
    const [numeroExt, setNumeroExt] = useState('');
    const [numeroInt, setNumeroInt] = useState('');
    const [colonia, setColonia] = useState('');
    const [municipio, setMunicipio] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [codigoPostal, setCodigoPostal] = useState('');
    const [horario, setHorario] = useState('');
    const [telefono, setTelefono] = useState('');
    const [referencia, setReferencia] = useState('');
    const [notas, setNotas] = useState('');

    // Estado para los mensajes de error
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('authLaravel'));
        const id_local = JSON.parse(localStorage.getItem('idUsuario'));
        axios.get('https://plsystem.quarkservers2.com/api/canje_inicio', {
            params: {
                id_usuario: id_local,
                id_cuenta: cuentaPL,
                prueba : 'no'
            }
        })
        .then(function (response) {
            setDatosUsuario(response.data.usuario);
            setListadoProductos(response.data.productos);
            setDatosCorte(response.data.corte);
            setDatosCorteUsuario(response.data.corte_usuario);
            setCreditosTotales(response.data.creditos_totales);
            setCreditosConsumidos(response.data.creditos_consumidos);
            setCreditosRestantes(response.data.creditos_restantes);
        })
        .catch(function (error) {
            // handle error
        });
    }, [recargarDatos]);


    useEffect(() => {
        // Obtener el carrito del localStorage al montar el componente
        const carritoGuardado = localStorage.getItem('carrito');
        if (carritoGuardado) {
          setCarrito(JSON.parse(carritoGuardado));
        }
      }, []);

      // controles del carrito

        // Función para incrementar la cantidad
        const incrementarDesdeCarrito = (index) => {
            const nuevoCarrito = carrito.map((item, i) => {
            if (i === index) {
                const nuevaCantidad = item.cantidad + 1;
                return {
                ...item,
                cantidad: nuevaCantidad,
                creditos_totales: nuevaCantidad * item.creditos_unidad,
                };
            }
            return item;
            });
        
            // Calcular el nuevo total de créditos
            const nuevoTotalCreditos = nuevoCarrito.reduce((total, item) => total + item.creditos_totales, 0);
        
            if (nuevoTotalCreditos > creditosRestantes) {
            Swal.fire({
                title: 'No cuentas con suficientes créditos',
                icon: 'warning',
                confirmButtonColor: '#213746',
                confirmButtonText: 'Aceptar'
            });
            return; // No se permite el incremento si supera los créditos restantes
            }
        
            setCarrito(nuevoCarrito);
            setTotalCreditos(nuevoTotalCreditos);
            localStorage.setItem('carrito', JSON.stringify(nuevoCarrito));
            localStorage.setItem('totalCreditos', nuevoTotalCreditos);
        };
  
        // Función para reducir la cantidad
        const decrementarDesdeCarrito = (index) => {
            const nuevoCarrito = carrito.map((item, i) => {
            if (i === index && item.cantidad > 1) {
                const nuevaCantidad = item.cantidad - 1;
                return {
                ...item,
                cantidad: nuevaCantidad,
                creditos_totales: nuevaCantidad * item.creditos_unidad,
                };
            }
            return item;
            });
        
            // Calcular el nuevo total de créditos
            const nuevoTotalCreditos = nuevoCarrito.reduce((total, item) => total + item.creditos_totales, 0);
        
            setCarrito(nuevoCarrito);
            setTotalCreditos(nuevoTotalCreditos);
            localStorage.setItem('carrito', JSON.stringify(nuevoCarrito));
            localStorage.setItem('totalCreditos', nuevoTotalCreditos);
        };


      const handleEliminar = (index) => {
        // Filtrar el carrito para eliminar el producto en el índice dado
        const carritoActualizado = carrito.filter((_, i) => i !== index);
        setCarrito(carritoActualizado);
    
        // Calcular el nuevo total de créditos después de la eliminación
        const nuevoTotalCreditos = carritoActualizado.reduce((total, item) => {
          return total + item.creditos_totales;
        }, 0);
    
        // Actualizar el estado del total de créditos
        setTotalCreditos(nuevoTotalCreditos);
    
        // Actualizar el carrito y el total de créditos en localStorage
        localStorage.setItem('carrito', JSON.stringify(carritoActualizado));
        localStorage.setItem('totalCreditos', nuevoTotalCreditos);
      };

      // Función para actualizar la cantidad de productos
      const actualizarCantidad = (index, nuevaCantidad) => {
        const nuevoCarrito = [...carrito];
        const producto = nuevoCarrito[index];
        const creditos_totales = nuevaCantidad * producto.creditos_unidad;
    
        // Calcular el total de créditos del carrito si se actualiza la cantidad
        const nuevoTotalCreditos = nuevoCarrito.reduce((total, item, i) => {
            if (i === index) {
                return total + creditos_totales;
            }
            return total + item.creditos_totales;
        }, 0);
    
        if (nuevoTotalCreditos > creditosRestantes) {
            Swal.fire({
                title: 'No cuentas con suficientes créditos',
                icon: 'warning',
                confirmButtonColor: '#213746',
                confirmButtonText: 'Aceptar'
            });
        } else {
            // Actualizar la cantidad en el carrito y los créditos totales
            nuevoCarrito[index].cantidad = parseInt(nuevaCantidad, 10);
            nuevoCarrito[index].creditos_totales = creditos_totales;
            setCarrito(nuevoCarrito); 
            setTotalCreditos(nuevoTotalCreditos);
            localStorage.setItem('carrito', JSON.stringify(nuevoCarrito));
            localStorage.setItem('totalCreditos', nuevoTotalCreditos);
        }
    };

      // Función para manejar el cambio de variación (si es necesario)
      const handleChangeVariacion = (index, nuevaVariacion) => {
        const nuevoCarrito = [...carrito];
        nuevoCarrito[index].variacion = nuevaVariacion;
        setCarrito(nuevoCarrito); 
        localStorage.setItem('carrito', JSON.stringify(nuevoCarrito));
    };

      // Control del formulario

      const handleSubmit = (e) => {
        e.preventDefault();
    
        // Validaciones
        const nuevosErrores = {};
        
        if (nombreCompleto.trim() === '') {
            nuevosErrores.nombreCompleto = 'El campo "Nombre completo" no puede estar vacío.';
        }
        if (calle.trim() === '') {
            nuevosErrores.calle = 'El campo "Calle" no puede estar vacío.';
        }
        if (numeroExt.trim() === '') {
            nuevosErrores.numeroExt = 'El campo "Número Ext" no puede estar vacío.';
        }
        if (colonia.trim() === '') {
            nuevosErrores.colonia = 'El campo "Colonia" no puede estar vacío.';
        }
        if (municipio.trim() === '') {
            nuevosErrores.municipio = 'El campo "Alcaldía / Municipio" no puede estar vacío.';
        }
        if (ciudad.trim() === '') {
            nuevosErrores.ciudad = 'El campo "Ciudad" no puede estar vacío.';
        }
        if (codigoPostal.trim() === '' || isNaN(codigoPostal)) {
            nuevosErrores.codigoPostal = 'El campo "Código Postal" debe ser un número y no puede estar vacío.';
        }
        if (telefono.trim() === '' || isNaN(telefono)) {
            nuevosErrores.telefono = 'El campo "Teléfono" debe ser un número y no puede estar vacío.';
        }
    
        // Si hay errores, no enviar el formulario y mostrar los errores
        if (Object.keys(nuevosErrores).length > 0) {
            setErrors(nuevosErrores);
            return;
        }
    
        let idCuenta = cuentaPL;
        let idUsuario = datosUsuario.id;
        let idCorte = datosCorte.id;
    
        // Si no hay errores, proceder con el envío del formulario
        const datosFormulario = {
            idCuenta,
            idUsuario,
            idCorte,
            carrito,
            nombreCompleto,
            calle,
            numeroExt,
            numeroInt,
            colonia,
            municipio,
            ciudad,
            codigoPostal,
            horario,
            telefono,
            referencia,
            notas
        };
    
        axios.post('https://plsystem.quarkservers2.com/api/canje_checkout', datosFormulario)
            .then(response => {
                if (response.data.success) {
                    // Limpiar el formulario
                    setNombreCompleto('');
                    setCalle('');
                    setNumeroExt('');
                    setNumeroInt('');
                    setColonia('');
                    setMunicipio('');
                    setCiudad('');
                    setCodigoPostal('');
                    setHorario('');
                    setTelefono('');
                    setReferencia('');
                    setNotas('');
                    setCarrito([]); // Si estás usando un estado para el carrito
    
                    // Limpiar el localStorage
                    localStorage.removeItem('carrito');
    
                    // Redirigir a la página de detalle
                    history.push('/recompensas/detalle/'+response.data.id_transaccion);
                } else {
                    console.error('Error al procesar la solicitud.');
                }
            })
            .catch(error => {
                console.error('Hubo un error!', error);
            });
    
        // Limpiar mensajes de error si todo está bien
        setErrors({});
    };
    

    if(datosCorteUsuario){   
        return (
        <div>
            <div className="rbt-breadcrumb-default ptb--100 ptb_md--50 ptb_sm--30 bg-gradient-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-inner text-center">
                                <h2 className="title">Recompensas - Checkout</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="checkout_area bg-color-white rbt-section-gap">
                <div className="container">
                <form onSubmit={handleSubmit}>
                    <div className="row g-5 checkout-form">

                        <div className="col-lg-8">
                            <div className="checkout-content-wrapper">
                                <div id="billing-form">
                                    <h4 className="checkout-title">Comparte tu dirección</h4>
                                        <div className="row">
                                        <div className="col-md-6 col-12 mb--20">
                                            <label>Nombre completo<strong style={{color:'#064a76'}}>*</strong></label>
                                            <input 
                                                type="text" 
                                                placeholder="Nombre completo" 
                                                value={nombreCompleto}
                                                onChange={(e) => setNombreCompleto(e.target.value)}
                                            />
                                            {/* Mostrar mensaje de error si el nombre está vacío */}
                                            {errors.nombreCompleto && <p style={{color: 'red'}}>{errors.nombreCompleto}</p>}
                                            </div>

                                            <div className="col-md-6 col-12 mb--20">
                                            <label>Calle<strong style={{color:'#064a76'}}>*</strong></label>
                                            <input 
                                                type="text" 
                                                placeholder="Calle" 
                                                value={calle}
                                                onChange={(e) => setCalle(e.target.value)}
                                            />
                                            {errors.calle && <p style={{color: 'red'}}>{errors.calle}</p>}
                                            </div>

                                            <div className="col-md-6 col-12 mb--20">
                                            <label>Número Ext<strong style={{color:'#064a76'}}>*</strong></label>
                                            <input 
                                                type="text" 
                                                placeholder="Número Ext" 
                                                value={numeroExt}
                                                onChange={(e) => setNumeroExt(e.target.value)}
                                            />
                                            {errors.numeroExt && <p style={{color: 'red'}}>{errors.numeroExt}</p>}
                                            </div>

                                            <div className="col-md-6 col-12 mb--20">
                                            <label>Número Int</label>
                                            <input 
                                                type="text" 
                                                placeholder="Número Int" 
                                                value={numeroInt}
                                                onChange={(e) => setNumeroInt(e.target.value)}
                                            />
                                            </div>

                                            <div className="col-md-6 col-12 mb--20">
                                            <label>Colonia<strong style={{color:'#064a76'}}>*</strong></label>
                                            <input 
                                                type="text" 
                                                placeholder="Colonia" 
                                                value={colonia}
                                                onChange={(e) => setColonia(e.target.value)}
                                            />
                                            {errors.colonia && <p style={{color: 'red'}}>{errors.colonia}</p>}
                                            </div>

                                            <div className="col-md-6 col-12 mb--20">
                                            <label>Alcaldía / Municipio<strong style={{color:'#064a76'}}>*</strong></label>
                                            <input 
                                                type="text" 
                                                placeholder="Alcaldía / Municipio" 
                                                value={municipio}
                                                onChange={(e) => setMunicipio(e.target.value)}
                                            />
                                            {errors.municipio && <p style={{color: 'red'}}>{errors.municipio}</p>}
                                            </div>

                                            <div className="col-md-4 col-12 mb--20">
                                            <label>Ciudad<strong style={{color:'#064a76'}}>*</strong></label>
                                            <input 
                                                type="text" 
                                                placeholder="Ciudad" 
                                                value={ciudad}
                                                onChange={(e) => setCiudad(e.target.value)}
                                            />
                                            {errors.ciudad && <p style={{color: 'red'}}>{errors.ciudad}</p>}
                                            </div>

                                            <div className="col-md-4 col-12 mb--20">
                                            <label>Código Postal<strong style={{color:'#064a76'}}>*</strong></label>
                                            <input 
                                                type="text" 
                                                placeholder="Código Postal"
                                                value={codigoPostal}
                                                onChange={(e) => setCodigoPostal(e.target.value)}
                                            />
                                            {errors.codigoPostal && <p style={{color: 'red'}}>{errors.codigoPostal}</p>}
                                            </div>

                                            {/* <div className="col-md-6 col-12 mb--20">
                                            <label htmlFor="horario">Horario preferente</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                id="horario" 
                                                placeholder="Ingresa un horario preferente" 
                                                value={horario}
                                                onChange={(e) => setHorario(e.target.value)}
                                            />
                                            </div> */}

                                            <div className="col-md-4 col-12 mb--20">
                                            <label>Teléfono<strong style={{color:'#064a76'}}>*</strong></label>
                                            <input 
                                                type="text" 
                                                placeholder="Ingresa un número telefónico"
                                                value={telefono}
                                                onChange={(e) => setTelefono(e.target.value)}
                                            />
                                            {errors.telefono && <p style={{color: 'red'}}>{errors.telefono}</p>}
                                            </div>

                                            <div className="form-group col-md-6">
                                            <label htmlFor="referencia">Referencia</label>
                                            <textarea 
                                                className="form-control" 
                                                id="referencia" 
                                                rows="2" 
                                                placeholder="Compártenos referencias para ubicar mejor tu dirección"
                                                value={referencia}
                                                onChange={(e) => setReferencia(e.target.value)}
                                            ></textarea>
                                            </div>

                                            <div className="form-group col-md-6">
                                            <label htmlFor="notas">Notas</label>
                                            <textarea 
                                                className="form-control" 
                                                id="notas" 
                                                rows="2" 
                                                placeholder="Agregar notas adicionales"
                                                value={notas}
                                                onChange={(e) => setNotas(e.target.value)}
                                            ></textarea>
                                            </div>
                                        </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="row pl--50 pl_md--0 pl_sm--0">
                                <div className="inner" style={{ textAlign: 'center' }}>
                                    <div className="content">
                                        <h5 style={{ fontWeight: '300' }}>CRÉDITOS DISPONIBLES: <strong>{creditosRestantes != null ? creditosRestantes.toLocaleString() : ''}</strong></h5>
                                        <div className="mb--20" style={{ marginBottom: '20px' }}>
                                            <a href="/recompensas/pedidos" className="rbt-badge-2" style={{ display: 'inline-block', textAlign: 'center' }}>
                                                <div className="image" style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '10px' }}>
                                                    <img src="assets/images/micrositio/RPLe ICONO recompensas.png" style={{ width: '30px', height: 'auto' }} />
                                                </div>
                                                <span style={{ verticalAlign: 'middle' }}>
                                                    Ver mi <strong>historial de canjes</strong>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                
                                <div className="col-12 mb--60">
                                <div className="checkout-cart-total text-center">
                                    <h4>TU CANJEO</h4>
                                    {carrito.length > 0 ? (
                                        carrito.map((producto, index) => (
                                        <div key={index} style={{ paddingBottom: '15px', marginBottom: '15px' }}>
                                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                                                <li style={{ padding: '10px 0' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <span style={{ fontSize: '18px', color: '#000' }}>{producto.nombre}</span>
                                                    <span style={{ fontSize: '18px', color: '#000', fontWeight: '600' }}>{producto.creditos_totales.toLocaleString()} créditos</span>
                                                    </div>
                                                    <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column' }}>

                                                    <select
                                                        style={{ height: 'auto', width: '150px', padding: '10px', marginBottom: '10px', fontSize: '14px' }}
                                                        onChange={(e) => handleChangeVariacion(index, e.target.value)}
                                                        >
                                                        {producto.variaciones
                                                            .filter((talla) => talla !== null)  // Filtra las tallas que no sean null
                                                            .map((talla, i) => (
                                                            <option 
                                                                key={i}
                                                                value={talla}
                                                                selected={producto.variacion === talla}>
                                                                Talla: {talla}
                                                            </option>
                                                            ))}
                                                    </select>

                                                    <select
                                                        style={{ height: 'auto', width: '150px', padding: '10px', fontSize: '14px' }}
                                                        value={producto.cantidad}
                                                        onChange={(e) => {
                                                            const nuevaCantidad = parseInt(e.target.value, 10);
                                                            const totalCreditosRequeridos = nuevaCantidad * producto.creditos_unidad;
                                                            if (totalCreditosRequeridos > creditosRestantes) {
                                                            Swal.fire({
                                                                title: 'No cuentas con suficientes créditos',
                                                                icon: 'warning',
                                                                confirmButtonColor: '#213746',
                                                                confirmButtonText: 'Aceptar'
                                                            });
                                                            } else {
                                                            actualizarCantidad(index, nuevaCantidad);
                                                            }
                                                        }}
                                                        >
                                                            {Array.from({ length: producto.cantidad_max }, (_, i) => i + 1).map((cantidad) => (
                                                                <option key={cantidad} value={cantidad}>
                                                                {cantidad}
                                                                </option>
                                                            ))}
                                                    </select>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        ))
                                    ) : (
                                        <div>
                                        <p>No hay productos en el carrito.</p>
                                        </div>
                                    )}


                                    <h5 className="mt-30">Créditos por aplicar <span> {totalCreditos != null ? totalCreditos.toLocaleString() : ''} </span></h5>
                                    {creditosRestantes >= totalCreditos && carrito?.length > 0 && (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                        {/* Botón de Volver */}
                                        <a
                                        type="button"
                                        className="rbt-btn btn-gradient btn-gradient-4 hover-icon-reverse"
                                        href='/recompensas'
                                        >
                                        Volver
                                        </a>

                                        {/* Botón de Canjear */}
                                        <button
                                        type="submit"
                                        className="rbt-btn btn-gradient hover-icon-reverse pl--30"
                                        style={{ backgroundColor: '#00274D', color: '#fff', width: '35%', paddingTop:'0px'}}
                                        >
                                        <span className="icon-reverse-wrapper">
                                            <span className="btn-text">Canjear</span>
                                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                        </span>
                                        </button>
                                    </div>
                                    )}


                                    {creditosRestantes < totalCreditos && (
                                        <h5 className="badge bg-danger mb-3">No tienes suficientes créditos</h5>
                                    )}

                                    {carrito?.length === 0 && (
                                        <h5 className="badge bg-danger mb-3">No hay productos en el carrito</h5>
                                    )}
                                    </div>
                                    {creditosRestantes>=totalCreditos? (
                                    <div className="addto-cart-btn mt--10" style={{float:'right'}}>
                                        
                                    </div>
                                    ):(
                                        <div className="addto-cart-btn mt--10" style={{float:'right'}}>
                                        <a className="btn btn-light hover-icon-reverse" href="#" disabled>
                                            <span className="icon-reverse-wrapper">
                                            <span className="btn-text">No tienes suficientes puntos</span>
                                            </span>
                                        </a>
                                    </div>
                                    )}

                                </div>
                            </div>
                        </div>

                    </div>
                </form>
                </div>
            </div>
        </div>
        );
    }
}

export function CheckoutDetail() {
    const history = useHistory(); // Hook para la navegación
    const {cuentaPL, setCuentaPL} = useVariablesGlobales();
    const { id } = useParams();

    const [datosUsuario, setDatosUsuario] = useState(null);
    const [datosTransaccion, setDatosTransaccion] = useState(null);
    const [productosTransaccion, setProductosTransaccion] = useState(null);

    // Variables del formulario 
    const [mostrarFormulario, setMostrarFormulario] = useState(false);
    const [nombreCompleto, setNombreCompleto] = useState('');
    const [calle, setCalle] = useState('');
    const [numeroExt, setNumeroExt] = useState('');
    const [numeroInt, setNumeroInt] = useState('');
    const [colonia, setColonia] = useState('');
    const [municipio, setMunicipio] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [codigoPostal, setCodigoPostal] = useState('');
    const [horario, setHorario] = useState('');
    const [telefono, setTelefono] = useState('');
    const [referencia, setReferencia] = useState('');
    const [notas, setNotas] = useState('');

    const [errors, setErrors] = useState({});

    // Función mostrar Formulario
    const mostrarFormularioHandler = () => {
        setMostrarFormulario(true);
    };

    // Función de envio
    const handleSubmit = (e) => {
        e.preventDefault();
    
        // Validaciones
        const nuevosErrores = {};
        
        if (nombreCompleto.trim() === '') {
            nuevosErrores.nombreCompleto = 'El campo "Nombre completo" no puede estar vacío.';
        }
        if (calle.trim() === '') {
            nuevosErrores.calle = 'El campo "Calle" no puede estar vacío.';
        }
        if (numeroExt.trim() === '') {
            nuevosErrores.numeroExt = 'El campo "Número Ext" no puede estar vacío.';
        }
        if (colonia.trim() === '') {
            nuevosErrores.colonia = 'El campo "Colonia" no puede estar vacío.';
        }
        if (municipio.trim() === '') {
            nuevosErrores.municipio = 'El campo "Alcaldía / Municipio" no puede estar vacío.';
        }
        if (ciudad.trim() === '') {
            nuevosErrores.ciudad = 'El campo "Ciudad" no puede estar vacío.';
        }
        if (codigoPostal.trim() === '' || isNaN(codigoPostal)) {
            nuevosErrores.codigoPostal = 'El campo "Código Postal" debe ser un número y no puede estar vacío.';
        }
        if (telefono.trim() === '' || isNaN(telefono)) {
            nuevosErrores.telefono = 'El campo "Teléfono" debe ser un número y no puede estar vacío.';
        }
    
        // Si hay errores, no enviar el formulario y mostrar los errores
        if (Object.keys(nuevosErrores).length > 0) {
            setErrors(nuevosErrores);
            return;
        }
        
        const idTransaccion = datosTransaccion.id;
        // Si no hay errores, proceder con el envío del formulario
        const datosFormulario = {
            idTransaccion,
            nombreCompleto,
            calle,
            numeroExt,
            numeroInt,
            colonia,
            municipio,
            ciudad,
            codigoPostal,
            horario,
            telefono,
            referencia,
            notas
        };
    
        axios.post('https://plsystem.quarkservers2.com/api/canje_checkout_actualizar', datosFormulario)
            .then(response => {
                if (response.data.success) {
    
                    console.log('actualizado')
                    setMostrarFormulario(false);
                } else {
                    console.error('Error al procesar la solicitud.');
                    console.error(response.data.error);
                }
            })
            .catch(error => {
                console.error('Hubo un error!', error);
            });
    
        // Limpiar mensajes de error si todo está bien
        setErrors({});
    };

    // Función de confirmación
    const confirmarTransaccion = useCallback(() => {
        const idTransaccion = datosTransaccion.id;
        const datosConfirmacion = { idTransaccion };
    
        axios.post('https://plsystem.quarkservers2.com/api/canje_checkout_confirmar', datosConfirmacion)
            .then(response => {
                if (response.data.success) {
                    //console.log('confirmado');
                    setMostrarFormulario(false);
                    history.push('/recompensas/pedidos');
                } else {
                    console.error('Error al procesar la solicitud.');
                    console.error(response.data.error);
                }
            })
            .catch(error => {
                console.error('Hubo un error!', error);
            });
    
        setErrors({});
    }, [datosTransaccion]);

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('authLaravel'));
        const id_local = JSON.parse(localStorage.getItem('idUsuario'));
        axios.get('https://plsystem.quarkservers2.com/api/detalles_transaccion', {
            params: {
                id_usuario: id_local,
                id_cuenta: cuentaPL,
                id_transaccion : id
            }
        })
        .then(function (response) {
            setDatosUsuario(response.data.usuario);
            setDatosTransaccion(response.data.transaccion);
            setProductosTransaccion(response.data.productos);
            /* Asigno los datos de la dirección */
            setNombreCompleto(response.data.transaccion.direccion_nombre);
            setCalle(response.data.transaccion.direccion_calle);
            setNumeroExt(response.data.transaccion.direccion_numero);
            setNumeroInt(response.data.transaccion.direccion_numeroint);
            setColonia(response.data.transaccion.direccion_colonia);
            setMunicipio(response.data.transaccion.direccion_municipio);
            setCiudad(response.data.transaccion.direccion_ciudad);
            setCodigoPostal(response.data.transaccion.direccion_codigo_postal);
            setHorario(response.data.transaccion.direccion_horario);
            setTelefono(response.data.transaccion.direccion_telefono);
            setReferencia(response.data.transaccion.direccion_referencia);
            setNotas(response.data.transaccion.direccion_notas);
        })
        .catch(function (error) {
            // handle error
        });
    }, [id]);

    const direccionFormateada = `
        ${nombreCompleto}
        ${calle} ${numeroExt}${numeroInt ? ' Int. ' + numeroInt : ''}
        Colonia: ${colonia}
        ${municipio ? municipio + ', ' : ''}${ciudad ? ciudad + ', ' : ''}${codigoPostal}
        Teléfono: ${telefono}
        Horario: ${horario}
        Referencia: ${referencia}
        Notas: ${notas}
    `.trim();


    if(productosTransaccion){
        return (
            <div>
                <div className="rbt-breadcrumb-default ptb--100 ptb_md--50 ptb_sm--30 bg-gradient-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-center">
                                    <h2 className="title">Recompensas - Detalle del Canje</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div className="rbt-cart-area bg-color-white rbt-section-gap pt--60">
                    <div className="cart_area">
                        <div className="container">
                            <div className="row">
                                <div className="col-8">
                                    <div className="cart-table table-responsive mb--60">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="pro-thumbnail" colSpan="2">Producto</th>
                                                    
                                                    <th className="pro-quantity">Cantidad</th>
                                                    <th className="pro-title">Créditos Unit</th>
                                                    <th className="pro-title">Créditos</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {productosTransaccion.map((producto, index) => {
                                                    return (
                                                    <tr key={index}>
                                                        <td className="pro-thumbnail pr--0 pl--20"><a href="#"><img src={`https://plsystem.quarkservers2.com/img/publicaciones/${producto.imagen}`} alt="Product"/></a></td>
                                                        <td className="pro-title"><a href="#">{producto.nombre} ({producto.variacion})</a></td>
                                                        <td className="pro-quantity"><span>{producto.cantidad}</span></td>
                                                        <td className="pro-price"><span>{producto.creditos_unitario} Créditos</span></td>
                                                        <td className="pro-price"><span>{producto.creditos_totales} Créditos</span></td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={mostrarFormulario ? 'd-none' : ''}>
                                        <table className="rbt-table table table-borderless">
                                            <tbody>
                                                {/* Fila del nombre completo */}
                                                <tr>
                                                    <td><strong>Nombre:</strong></td>
                                                    <td>{nombreCompleto}</td>
                                                </tr>

                                                {/* Fila de la dirección */}
                                                <tr>
                                                    <td><strong>Dirección:</strong></td>
                                                    <td>
                                                        {calle} {numeroExt} {numeroInt && `Int. ${numeroInt}`} 
                                                        Colonia: {colonia}<br />
                                                        {municipio && `${municipio}, `}{ciudad && `${ciudad}, `}{codigoPostal}<br />
                                                        Teléfono: {telefono}
                                                    </td>
                                                </tr>

                                                {/* Fila de las notas, horario y referencias */}
                                                <tr>
                                                    <td><strong>Detalles:</strong></td>
                                                    <td>
                                                        Horario: {horario}<br />
                                                        Referencia: {referencia}<br />
                                                        Notas: {notas}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <button onClick={mostrarFormularioHandler} className={datosTransaccion.confirmado === 'si' ? 'd-none' : 'single-button w-100 ms-auto rbt-btn btn-gradient rbt-switch-btn rbt-switch-y w-100'}>
                                            Editar Dirección
                                        </button>
                                    </div>

                                    <div className={mostrarFormulario ? '' : 'd-none'}>
                                    <form onSubmit={handleSubmit}>
                                        <div className="row g-5 checkout-form">

                                            <div className="col-lg-12">
                                                <div className="checkout-content-wrapper">
                                                    <div id="billing-form">
                                                        <h4 className="checkout-title">Actualiza tu dirección</h4>
                                                            <div className="row">
                                                            <div className="col-md-6 col-12 mb--20">
                                                                <label>Nombre completo<strong style={{color:'#064a76'}}>*</strong></label>
                                                                <input 
                                                                    type="text" 
                                                                    placeholder="Nombre completo" 
                                                                    value={nombreCompleto || ''}
                                                                    onChange={(e) => setNombreCompleto(e.target.value)}
                                                                />
                                                                {/* Mostrar mensaje de error si el nombre está vacío */}
                                                                {errors.nombreCompleto && <p style={{color: 'red'}}>{errors.nombreCompleto}</p>}
                                                                </div>

                                                                <div className="col-md-6 col-12 mb--20">
                                                                <label>Calle<strong style={{color:'#064a76'}}>*</strong></label>
                                                                <input 
                                                                    type="text" 
                                                                    placeholder="Calle" 
                                                                    value={calle || ''}
                                                                    onChange={(e) => setCalle(e.target.value)}
                                                                />
                                                                {errors.calle && <p style={{color: 'red'}}>{errors.calle}</p>}
                                                                </div>

                                                                <div className="col-md-6 col-12 mb--20">
                                                                <label>Número Ext<strong style={{color:'#064a76'}}>*</strong></label>
                                                                <input 
                                                                    type="text" 
                                                                    placeholder="Número Ext" 
                                                                    value={numeroExt || ''}
                                                                    onChange={(e) => setNumeroExt(e.target.value)}
                                                                />
                                                                {errors.numeroExt && <p style={{color: 'red'}}>{errors.numeroExt}</p>}
                                                                </div>

                                                                <div className="col-md-6 col-12 mb--20">
                                                                <label>Número Int</label>
                                                                <input 
                                                                    type="text" 
                                                                    placeholder="Número Int" 
                                                                    value={numeroInt || ''}
                                                                    onChange={(e) => setNumeroInt(e.target.value)}
                                                                />
                                                                </div>

                                                                <div className="col-md-6 col-12 mb--20">
                                                                <label>Colonia<strong style={{color:'#064a76'}}>*</strong></label>
                                                                <input 
                                                                    type="text" 
                                                                    placeholder="Colonia" 
                                                                    value={colonia || ''}
                                                                    onChange={(e) => setColonia(e.target.value)}
                                                                />
                                                                {errors.colonia && <p style={{color: 'red'}}>{errors.colonia}</p>}
                                                                </div>

                                                                <div className="col-md-6 col-12 mb--20">
                                                                <label>Alcaldía / Municipio<strong style={{color:'#064a76'}}>*</strong></label>
                                                                <input 
                                                                    type="text" 
                                                                    placeholder="Alcaldía / Municipio" 
                                                                    value={municipio || ''}
                                                                    onChange={(e) => setMunicipio(e.target.value)}
                                                                />
                                                                {errors.municipio && <p style={{color: 'red'}}>{errors.municipio}</p>}
                                                                </div>

                                                                <div className="col-md-6 col-12 mb--20">
                                                                <label>Ciudad<strong style={{color:'#064a76'}}>*</strong></label>
                                                                <input 
                                                                    type="text" 
                                                                    placeholder="Ciudad" 
                                                                    value={ciudad || ''}
                                                                    onChange={(e) => setCiudad(e.target.value)}
                                                                />
                                                                {errors.ciudad && <p style={{color: 'red'}}>{errors.ciudad}</p>}
                                                                </div>

                                                                <div className="col-md-6 col-12 mb--20">
                                                                <label>Código Postal<strong style={{color:'#064a76'}}>*</strong></label>
                                                                <input 
                                                                    type="text" 
                                                                    placeholder="Código Postal"
                                                                    value={codigoPostal || ''}
                                                                    onChange={(e) => setCodigoPostal(e.target.value)}
                                                                />
                                                                {errors.codigoPostal && <p style={{color: 'red'}}>{errors.codigoPostal}</p>}
                                                                </div>
{/* 
                                                                <div className="col-md-6 col-12 mb--20">
                                                                <label htmlFor="horario">Horario preferente</label>
                                                                <input 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    id="horario" 
                                                                    placeholder="Ingresa un horario preferente" 
                                                                    value={horario || ''}
                                                                    onChange={(e) => setHorario(e.target.value)}
                                                                />
                                                                </div> */}

                                                                <div className="col-md-6 col-12 mb--20">
                                                                <label>Teléfono<strong style={{color:'#064a76'}}>*</strong></label>
                                                                <input 
                                                                    type="text" 
                                                                    placeholder="Ingresa un número telefónico"
                                                                    value={telefono || ''}
                                                                    onChange={(e) => setTelefono(e.target.value)}
                                                                />
                                                                {errors.telefono && <p style={{color: 'red'}}>{errors.telefono}</p>}
                                                                </div>

                                                                <div className="form-group col-md-6">
                                                                    <label htmlFor="referencia">Referencia</label>
                                                                    <textarea 
                                                                        className="form-control" 
                                                                        id="referencia" 
                                                                        rows="2" 
                                                                        placeholder="Compártenos referencias para ubicar mejor tu dirección"
                                                                        value={referencia || ''}
                                                                        onChange={(e) => setReferencia(e.target.value)}
                                                                    ></textarea>
                                                                </div>

                                                                <div className="form-group col-md-6">
                                                                    <label htmlFor="notas">Notas</label>
                                                                    <textarea 
                                                                        className="form-control" 
                                                                        id="notas" 
                                                                        rows="2" 
                                                                        placeholder="Agregar notas adicionales"
                                                                        value={notas || ''}
                                                                        onChange={(e) => setNotas(e.target.value)}
                                                                    ></textarea>
                                                                </div>
                                                                <button type='submit' className="rbt-btn btn-gradient rbt-switch-btn rbt-switch-y">
                                                                    Actualizar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    
        
                                </div>
                                <div className={mostrarFormulario ? 'd-none' : 'col-4'}>
                                            <div className="cart-summary">
                                                <div className="cart-summary-wrap">
                                                    <div className="section-title text-start">
                                                        <h4 className="title mb--30">Créditos totales</h4>
                                                    </div>
                                                    <h2>Total <span>{datosTransaccion.creditos.toLocaleString('es-MX')}</span></h2>
                                                </div>
        
                                                <div className={datosTransaccion.confirmado === 'si' ? 'd-none' : 'cart-submit-btn-group d-flex'}>
                                                    <div className="single-button w-100 ms-auto">
                                                        <button type='button' onClick={confirmarTransaccion}className="rbt-btn btn-gradient rbt-switch-btn rbt-switch-y w-100">
                                                            Confirmar
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className={datosTransaccion.confirmado === 'si' ? 'cart-submit-btn-group d-flex': 'd-none'}>
                                                    <div className="single-button w-100 ms-auto">
                                                        <a href='/recompensas/pedidos' className="rbt-btn btn-gradient rbt-switch-btn rbt-switch-y w-100">
                                                            Ver mis recompensas
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            );
    }
}

export function CheckoutOrderDetail() {
    const history = useHistory(); // Hook para la navegación
    const {cuentaPL, setCuentaPL} = useVariablesGlobales();

    const [datosUsuario, setDatosUsuario] = useState(null);
    const [listaTransacciones, setListaTransacciones] = useState(null);
    const [listadoProductos, setListadoProductos] = useState(null);
    const [datosCorte, setDatosCorte] = useState(null);
    const [creditosTotales, setCreditosTotales] = useState(null);
    const [creditosConsumidos, setCreditosConsumidos] = useState(null);
    const [creditosRestantes, setCreditosRestantes] = useState(null);
    const [datosCorteUsuario, setDatosCorteUsuario] = useState(null);
    const [mostrarCanjeo, setMostrarCanjeo] = useState(false);
    const [recargarDatos, setRecargarDatos] = useState(false);

useEffect(() => {
        const token = JSON.parse(localStorage.getItem('authLaravel'));
        const id_local = JSON.parse(localStorage.getItem('idUsuario'));
        axios.get('https://plsystem.quarkservers2.com/api/canje_inicio', {
            params: {
                id_usuario: id_local,
                id_cuenta: cuentaPL,
                prueba : 'no'
            }
        })
        .then(function (response) {
            setDatosUsuario(response.data.usuario);
            setListadoProductos(response.data.productos);
            setDatosCorte(response.data.corte);
            setDatosCorteUsuario(response.data.corte_usuario);
            setCreditosTotales(response.data.creditos_totales);
            setCreditosConsumidos(response.data.creditos_consumidos);
            setCreditosRestantes(response.data.creditos_restantes);
        })
        .catch(function (error) {
            // handle error
        });
    }, [recargarDatos]);

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('authLaravel'));
        const id_local = JSON.parse(localStorage.getItem('idUsuario'));
        axios.get('https://plsystem.quarkservers2.com/api/lista_transacciones', {
            params: {
                id_usuario: id_local,
                id_cuenta: cuentaPL
            }
        })
        .then(function (response) {
            setDatosUsuario(response.data.usuario);
            setListaTransacciones(response.data.transacciones);
        })
        .catch(function (error) {
            // handle error
        });
    }, []);

    if(listaTransacciones){
        return (
            <div>
                <div className="rbt-breadcrumb-default ptb--100 ptb_md--50 ptb_sm--30 bg-gradient-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-center">
                                    <h2 className="title">Lista de Recompensas</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="rbt-accordion-area accordion-style-1 bg-color-white rbt-section-gap pt--60 pb--0">
                    <div class="container">
                        <div class="row g-5 align-items-start">
                            <div class="col-lg-10 order-2 order-lg-1">
                                <div class="rbt-accordion-style rbt-accordion-01 rbt-accordion-06 accordion">
                                    <div class="accordion" id="tutionaccordionExamplea1">
                                        <div class="accordion-item card">
                                            <h2 class="accordion-header card-header" id="tutionheadingOne">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#tutioncollapseOne" aria-expanded="false" aria-controls="tutioncollapseOne">
                                                Pedidos pendientes

                                                </button>
                                            </h2>
                                            <div id="tutioncollapseOne" class="accordion-collapse collapse" aria-labelledby="tutionheadingOne" data-bs-parent="#tutionaccordionExamplea1">
                                                <div class="accordion-body card-body">
                                                    <div class="table-responsive mobile-table-750">
                                                        <div className="section-title text-center mb--60">
                                                            <table className="rbt-table table table-borderless">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="text-center" style={{width:'20px'}}>#FOLIO</th>
                                                                        <th>RECOMPENSAS</th>
                                                                        <th>NOMBRE</th>
                                                                        <th>DIRECCION</th>
                                                                        <th className='d-none'>CREDITOS</th>
                                                                        <th>CONFIRMADO</th>
                                                                        <th>ENVIADO</th>
                                                                        <th className='d-none'>CONTROL</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={{verticalAlign:'middle'}}>
                                                                    {listaTransacciones.map((transaccion, index) => {
                                                                        return (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                {transaccion.id}
                                                                            </td>
                                                                            <td>
                                                                                {/* Tabla anidada para los productos */}
                                                                                <table className="rbt-table table table-borderless">
                                                                                <tbody>
                                                                                    {transaccion.productos.map((producto, productoIndex) => (
                                                                                    <tr key={productoIndex}>
                                                                                        <td>
                                                                                        {/* <img
                                                                                            src={`https://plsystem.quarkservers2.com/img/publicaciones/${producto.imagen}`}
                                                                                            alt={producto.nombre}
                                                                                            style={{ width: '40px', height: '40px' }}
                                                                                        /> */}
                                                                                        </td>
                                                                                        <td className='text-left'>{producto.nombre}</td>
                                                                                        <td className='text-left' style={{color:'#064a76', fontWeight:'700'}}>{producto.variacion}</td>
                                                                                    </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                                </table>
                                                                            </td>
                                                                            <td className='text-left'>{transaccion.direccion_nombre}</td>
                                                                            <td className='text-left' style={{fontSize:'14px'}}>
                                                                            {transaccion.direccion_calle} {transaccion.direccion_numero} {transaccion.direccion_numeroint} 
                                                                                Colonia: {transaccion.direccion_colonia}<br />
                                                                                {transaccion.direccion_municipio && `${transaccion.direccion_municipio}, `}{transaccion.direccion_ciudad && `${transaccion.direccion_ciudad}, `}{transaccion.codigo_postal}<br />
                                                                                Teléfono: {transaccion.direccion_telefono}
                                                                            </td>
                                                                            <td className='text-left d-none'>{transaccion.creditos.toLocaleString('es-MX')}</td>

                                                                            <td className='text-left'>{transaccion.confirmado}</td>
                                                                            <td className='text-left'>{transaccion.enviado}</td>
                                                                            <td className='text-left d-none'>
                                                                                <a className='rbt-btn-link ml--30' href={`/recompensas/detalle/${transaccion.id}`}>Ver detalles</a>
                                                                            </td>
                                                                        </tr>
                                                                        );
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item card">
                                            <h2 class="accordion-header card-header" id="tutionheadingTwo">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#tutioncollapseTwo" aria-expanded="false" aria-controls="tutioncollapseTwo">
                                                Pedidos completados
                                                </button>
                                            </h2>
                                            <div id="tutioncollapseTwo" class="accordion-collapse collapse" aria-labelledby="tutionheadingTwo" data-bs-parent="#tutionaccordionExamplea1">
                                                <div class="accordion-body card-body">
                                                    <div class="table-responsive mobile-table-750">
                                                        <table class="rbt-table table table-borderless">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-center" style={{width:'20px'}}>#FOLIO</th>
                                                                    <th>RECOMPENSAS</th>
                                                                    <th>NOMBRE</th>
                                                                    <th>DIRECCION</th>
                                                                    <th>CREDITOS</th>
                                                                    <th>CONFIRMADO</th>
                                                                    <th>ENVIADO</th>
                                                                    <th>CONTROL</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody style={{verticalAlign:'middle'}}>
                                                                <tr>
                                                                    <td colSpan={'6'}>
                                                                    Aun no hay productos completados
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 order-1 order-lg-2">
                                <div class="thumbnail">
                                        <div className="rbt-counterup style-2">
                                            <div className="inner">
                                                <div className="content text-end">
                                                    {/* <h1 className=""><span className="odometer" data-count="800">{creditosRestantes}</span></h1> */}
                                                    <h1 className=""><span className="odometer" data-count="800">{creditosRestantes != null ? creditosRestantes.toLocaleString() : ''}
                                                    </span></h1>
                                                    <span className="subtitle">Créditos disponibles</span>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rbt-counterup style-2 mt--30">
                                            <div className="inner">
                                                <div className="content text-end">
                                                    {/* <h1 className=""><span className="odometer" data-count="100">{creditosConsumidos}</span></h1> */}
                                                    <h1 className=""><span className="odometer" data-count="100">{creditosConsumidos != null ? creditosConsumidos.toLocaleString() : ''}</span></h1>
                                                    <span className="subtitle">Créditos canjeados</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rbt-counterup style-2 mt--30">
                                            <div className="inner">
                                                <div className="content text-end">
                                                    <a class="rbt-btn hover-icon-reverse" href="/recompensas">
                                                        <span class="icon-reverse-wrapper">
                                                            <span class="btn-text">Volver</span>
                                                        <span class="btn-icon"><i class="feather-arrow-right"></i></span>
                                                        <span class="btn-icon"><i class="feather-arrow-right"></i></span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                
            </div>
            );
    }
}

export default HomeCanje;