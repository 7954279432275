import React, { useState, useEffect } from "react";
import ReactPlayer from 'react-player';

import BannerWhatsapp from "../components/sections";
import VideoComponent from "../components/ModalComponent";
import { Container, Row, Col, Carousel, Card, Button, ProgressBar, Modal, Form,  FormCheck, ModalHeader  } from 'react-bootstrap';
import { Link } from 'react-router-dom'; 

import {   BrowserRouter as Router,
    useHistory, useParams } from 'react-router-dom';
  import { useVariablesGlobales } from '../globales/VariablesGlobales';
  import axios from 'axios';

function Home() {

    /**
     * Variables globales y datos
     */

    const {cuentaPL, setCuentaPL} = useVariablesGlobales();
    const [datosTemporada, setDatosTemporada] = useState(null);
    const [datosSlider, setDatosSlider] = useState(null);
    const [listadoSesiones, setListadoSesiones] = useState(null);
    const [datosTerminos, setDatosTerminos] = useState(null);

    /**
     * Variables de interactividad
     */
    const [playVideo, setPlayVideo] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isChecked, setIsChecked] = useState(false);
    const [show, setShow] = useState(false);
    

    /**
     * Funciones de interactividad
     */

    const handlePlayClick = () => {
        setPlayVideo(true);
    };

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
      };

    //Aqui va el modal del Jackpot
    const [showPopup, setShowPopup] = useState(false); 

    const handleClosePopup = () => {
        setShowPopup(false);
        localStorage.setItem('popupClosed', new Date().getTime().toString()); 
    };

    const handlePlayNow = () => {
        setShowPopup(false);
        localStorage.setItem('popupClosed', new Date().getTime().toString()); // Guardar el tiempo en que se cerró el modal al hacer clic en "Ir ahora"
    };

    useEffect(() => {
        const popupClosed = localStorage.getItem('popupClosed');
        const currentTime = new Date().getTime();

        
        const startDate = new Date('2024-10-04T09:00:00');
        const endDate = new Date('2024-10-05T11:00:00');

        const isInDateRange = currentTime >= startDate.getTime() && currentTime <= endDate.getTime();

        if (popupClosed) {
            const closedTime = parseInt(popupClosed);
            // Si han pasado menos de 10 minutos desde que se cerró, no mostrar el modal
            if (currentTime - closedTime < 10 * 60 * 1000) {
                return;
            }
        }

        if (isInDateRange) {
            setShowPopup(true);
        }
    }, []);


    // Función para cerrar el modal
    const handleClose = () => setShow(false);

    const [showModal, setShowModal] = useState(false);

    const handlePlayClick2 = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

     // Función para manejar el clic en el botón Aceptar
  const handleAccept = () => {
    localStorage.setItem('aceptados_terminos', 'true'); // Guarda la variable local
    setShow(false); // Cierra el modal
  };

    /**
     * Funciones de UseEffect
     */

    // Función para mostrar el modal automáticamente al cargar la página
    useEffect(() => {
        const aceptadosTerminos = localStorage.getItem('aceptados_terminos');
        const token = localStorage.getItem('authLaravel');
        if (!aceptadosTerminos&&token) {
        setShow(true);
        }
    }, []);

    useEffect(() => {
        if(loading){
            // Realiza la solicitud al servidor solo si id es válido
            axios.get('https://plsystem.quarkservers2.com/api/datos_publicacion', {
                params: {
                id: 1
                }
            })
            .then(function (response) {
                //console.log(response.data);
                setDatosTerminos(response.data);
                setLoading(false);
                
            })
            .catch(function (error) {
                console.log('El error fue:' + error);
                setLoading(false);
            });
        }
        
    }, [loading]); // Solo depende de loading

    useEffect(() => {
        // llamada para los datos de la temporada
        axios.get('https://plsystem.quarkservers2.com/api/datos_temporada', {
            params: {
              id: cuentaPL
            }
          })
          .then(function (response) {
            //console.log(response);
            setDatosTemporada(response.data);
            
          })
          .catch(function (error) {
            // handle error
            console.log('El error fue:'+error);
          });
    
      }, []);

    useEffect(() => {
        if(datosTemporada){
            // llamada para los datos de la temporada
                axios.get('https://plsystem.quarkservers2.com/api/datos_slider', {
                params: {
                    id_temporada: datosTemporada.id
                }
            })
            .then(function (response) {
                setDatosSlider(response.data);
                //console.log(response.data);
                
            })
            .catch(function (error) {
                // handle error
                console.log('El error fue:'+error);
            });
        }
    
    }, [datosTemporada]);

    useEffect(()=>{
        // llamada para el listado de sesiones
        if(datosTemporada){
          axios.get('https://plsystem.quarkservers2.com/api/lista_sesiones', {
            params: {
              id_temporada: datosTemporada.id
            }
          })
          .then(function (response) {
            //console.log(response);
            setListadoSesiones(response.data);
            
          })
          .catch(function (error) {
            // handle error
            console.log('El error fue:'+error);
          });
        }
          
      }, [datosTemporada]);

   
    
    if(datosSlider){
        return (
            <div>
                {/* Start Banner Area */}
                <div className="rbt-splash-slider d-flex align-items-center">
                    <div className="wrapper w-100">
                        <div className="container">
                            <div className="row g-5 justify-content-between align-items-center">
                                <div className="col-lg-6 order-2 order-lg-1">
                                    <div className="content">
                                        <div className="inner">
                                            {/* En esta burbuja ver la manaera de personalizarla desde el panel */}
                                            <div className="rbt-new-badge rbt-new-badge-one mb--30">
                                                <span className="rbt-new-badge-icon"> Segunda Temporada</span> 
                                            </div>
                                            <h1 className="title" style={{color:'#fff'}}>Bienvenido a <span className="" style={{color:'#f0b323'}}>PLearning Soluciones Eléctricas: </span>  ¡Últimas actividades de temporada!</h1>
                                            <div className="rbt-button-group justify-content-start mt--30">
                                                <a className="rbt-btn btn-gradient rbt-switch-btn rbt-switch-y" href="#" onClick={handlePlayClick2}>
                                                    <span data-text="Ver actividades">Ver actividades </span>
                                                </a>
                                                {/* <a className="rbt-btn btn-border rbt-switch-btn" href="#" style={{color:'#fff'}}>
                                                    <span data-text="Find Courses">Find Courses</span>
                                                </a> */}
                                            </div>
                                        </div>
                                    </div>    
                                </div>
                                <div className="col-lg-6 order-1 order-lg-2">
                                    
                                <div className="video-popup-wrapper mt_lg--10 mt_md--20 mt_sm--20">
                                    <div className="video-container">
                                        <img
                                            className="w-100 rbt-radius"
                                            src="assets/images/micrositio/PLe_HomeVideo2_2024.jpeg"
                                            alt="Video Images"
                                        />
                                        <button
                                            className="rbt-btn rounded-player-2 sm-size popup-video position-to-top with-animation"
                                            onClick={handlePlayClick2}
                                        >
                                            <span className="play-icon"></span>
                                        </button>
                                    </div>
                                </div>

                                <Modal
                                    show={showModal}
                                    onHide={handleCloseModal}
                                    centered
                                    size="lg"
                                    dialogClassName="transparent-modal"
                                >
                                    <Modal.Body className="d-flex justify-content-center align-items-center p-0" style={{ height: '80vh' }}>
                                        <div className="modal-content p-0">
                                            <ReactPlayer
                                                url="https://vimeo.com/1014405572"
                                                width="100%"
                                                height="100%"
                                                controls
                                                playing
                                            />
                                        </div>
                                    </Modal.Body>
                                </Modal>

                                    
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>

                
                {/* End Banner Area */}
                {/* Start Service Area */}
                <div className="service-wrapper rbt-section-gap bg-color-white pb--40" style={{paddingTop: '40px'}}>
                    <div className="container">
                        <div className="row mb--60">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h2 className="title">Traemos sorpresas</h2>
                                    {/* <p className="description has-medium-font-size mt--20">There are many variations of passages of the
                                        Ipsum available, but the majority have suffered alteration in some form, by injected humour.</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                                <div className="swiper service-item-3-activation rbt-arrow-between gutter-swiper-30 swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">

                                <div className="swiper-wrapper" id="swiper-wrapper-c15f73fcaaa32863" aria-live="polite" style={{transform: 'translate3d(-445px, 0px, 0px)', transitionDuration: '0ms'}}>
                                    {/* Start Single Card  */}
                                    <div className="swiper-slide swiper-slide-prev" role="group" aria-label="1 / 4" style={{width: '445px'}}>
                                        <div className="single-slide">
                                            <div className="rbt-service rbt-service-2 rbt-hover-02 bg-no-shadow card-bg-1">
                                                <div className="inner">
                                                    <div className="content">
                                                        <h4 className="title"><a href="#">React</a></h4>
                                                        <p>React Js dolor sit, amet consectetur.</p>
                                                        <a className="transparent-button" href="#">Learn More<i><svg width="17" height="12" xmlns="http://www.w3.org/2000/svg"><g stroke="#27374D" fill="none" fillRule="evenodd"><path d="M10.614 0l5.629 5.629-5.63 5.629"></path><path strokeLinecap="square" d="M.663 5.572h14.594"></path></g></svg></i></a>
                                                    </div>
                                                    <div className="thumbnail">
                                                        <img src="/assets/images/micrositio/PLe_HomeNovedadA_2024.png" alt="Education Images" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Card  */}
                                    {/* Start Single Card  */}
                                    <div className="swiper-slide" role="group" aria-label="4 / 4" style={{width: '445px'}}>
                                        <div className="single-slide">
                                            <div className="rbt-service rbt-service-2 rbt-hover-02 bg-no-shadow" style={{background:'#0A78C2'}}>
                                                <div className="inner">
                                                    <div className="content">
                                                        <h4 className="title"><a href="#">Panel de Líderes</a></h4>
                                                        <p style={{color:'#D4EDDD'}}>Lleva a tu compañía a la victoria </p>
                                                        {/* <a className="transparent-button" href="#">Learn More<i><svg width="17" height="12" xmlns="http://www.w3.org/2000/svg"><g stroke="#27374D" fill="none" fillRule="evenodd"><path d="M10.614 0l5.629 5.629-5.63 5.629"></path><path strokeLinecap="square" d="M.663 5.572h14.594"></path></g></svg></i></a> */}
                                                    </div>
                                                    <div className="thumbnail">
                                                        <img src="/assets/images/micrositio/PLe_HomeNovedadA_2024.png" alt="Education Images" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Card  */}
                                    {/* Start Single Card  */}
                                    <div className="swiper-slide swiper-slide-active" role="group" aria-label="2 / 4" style={{width: '445px'}}>
                                        <div className="single-slide">
                                            <div className="rbt-service rbt-service-2 rbt-hover-02 bg-no-shadow" style={{background:'#E5E6E8'}}>
                                                <div className="inner">
                                                    <div className="content">
                                                        <h4 className="title"><a href="#">Jackpot</a></h4>
                                                        <p>Cada mes, gana y diviértete con nuestro jackpot</p>
                                                        <a className="transparent-button" href="/jackpot">Saber más<i><svg width="17" height="12" xmlns="http://www.w3.org/2000/svg"><g stroke="#27374D" fill="none" fillRule="evenodd"><path d="M10.614 0l5.629 5.629-5.63 5.629"></path><path strokeLinecap="square" d="M.663 5.572h14.594"></path></g></svg></i></a>
                                                    </div>
                                                    <div className="thumbnail">
                                                        <img src="/assets/images/micrositio/PLe_HomeNovedadB_2024.png" alt="Education Images" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Card  */}
                                    {/* Start Single Card  */}
                                    <div className="swiper-slide swiper-slide-next" role="group" aria-label="3 / 4" style={{width: '445px'}}>
                                        <div className="single-slide">
                                            <div className="rbt-service rbt-service-2 rbt-hover-02 bg-no-shadow" style={{background:'#F6D079'}}>
                                                <div className="inner">
                                                    <div className="content">
                                                        <h4 className="title"><a href="/recompensas">Premios Especiales</a></h4>
                                                        <p>Convierte tus créditos en regalos </p>
                                                        <a className="transparent-button" href="/recompensas">Saber más<i><svg width="17" height="12" xmlns="http://www.w3.org/2000/svg"><g stroke="#27374D" fill="none" fillRule="evenodd"><path d="M10.614 0l5.629 5.629-5.63 5.629"></path><path strokeLinecap="square" d="M.663 5.572h14.594"></path></g></svg></i></a>
                                                    </div>
                                                    <div className="thumbnail">
                                                        <img src="/assets/images/micrositio/PLe_HomeNovedadC_2024.png" alt="Education Images" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Card  */}

                                    
                                </div>

                                {/* <div className="rbt-swiper-arrow rbt-arrow-left swiper-button-disabled" tabIndex="-1" role="button" aria-label="Next slide" aria-controls="swiper-wrapper-c15f73fcaaa32863" aria-disabled="true">
                                    <div className="custom-overfolow">
                                        <i className="rbt-icon feather-arrow-left"></i>
                                        <i className="rbt-icon-top feather-arrow-left"></i>
                                    </div>
                                </div>

                                <div className="rbt-swiper-arrow rbt-arrow-right" tabIndex="0" role="button" aria-label="Previous slide" aria-controls="swiper-wrapper-c15f73fcaaa32863" aria-disabled="false">
                                    <div className="custom-overfolow">
                                        <i className="rbt-icon feather-arrow-right"></i>
                                        <i className="rbt-icon-top feather-arrow-right"></i>
                                    </div>
                                </div> */}
                                <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                            </div>


                        </div>
                    </div>
                </div>
                {/* End Service Area */}
                {/* Start About Area */}
                <div className="rbt-about-area about-style-1 bg-color-white rbt-section-gapTop">
                    <div className="container">
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-6">
                                <div className="thumbnail-wrapper">
                                    <div className="thumbnail image-1">
                                        <img data-parallax='{"x": 0, "y": -20}' src="assets/images/micrositio/PLe_HomeDChA_2024.png" alt="Education Images" />
                                    </div>
                                    <div className="thumbnail image-2 d-none d-xl-block">
                                        <img data-parallax='{"x": 0, "y": 60}' src="assets/images/micrositio/PLe_HomeDChB_2024.png" alt="Education Images" />
                                    </div>
                                    <div className="thumbnail image-3 d-none d-md-block">
                                        <img data-parallax='{"x": 0, "y": 80}' src="assets/images/micrositio/PLe_HomeDChC_2024.png" alt="Education Images" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="inner pl--50 pl_sm--0 pl_md--0">
                                    <div className="section-title text-start">
                                        <h2 className="title">Elige tu desafío. Vende productos participantes. Gana premios en efectivo. </h2>
                                    </div>
                                    <p className="description mt--30">
                                        Desafío Champions 2024</p>
                                    {/*Start Feature List  */}
                                    <div className="rbt-feature-wrapper mt--40">
                                        <div className="rbt-feature feature-style-1">
                                            <div className="icon bg-icon-opacity-1">
                                                <i className="feather-dollar-sign "></i>
                                            </div>
                                            <div className="feature-content">
                                                <h6 className="feature-title">Vende Productos</h6>
                                                <p className="feature-description">Vende los productos participantes durante la vigencia del programa</p>
                                            </div>
                                        </div>
                                        <div className="rbt-feature feature-style-1">
                                            <div className="icon bg-icon-opacity-2">
                                                <i className="feather-trending-up"></i>
                                            </div>
                                            <div className="feature-content">
                                                <h6 className="feature-title">Alcance de metas</h6>
                                                <p className="feature-description">Alcanza las metas objetivo de cada nivel para obtener un bono</p>
                                            </div>
                                        </div>
                                        <div className="rbt-feature feature-style-1">
                                            <div className="icon bg-icon-opacity-3">
                                                <i className="feather-check-circle"></i>
                                            </div>
                                            <div className="feature-content">
                                                <h6 className="feature-title">Verificacíon</h6>
                                                <p className="feature-description">Envíanos tus pruebas de compra (factura u orden de compra)</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/*End Feature List  */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <BannerWhatsapp />

                
                {/* Modal Jackpot */}
                {/* <Modal className="popup-jackpot" show={showPopup} onHide={handleClosePopup} backdrop="static" keyboard={false}>
                    <Modal.Header style={{ background: '#fff', textAlign: 'center', borderBottom: 'none' }}>
                        <button type="button" className="btn-close" aria-label="Close" style={{ fontSize: '1rem', padding: '0.5rem' }} onClick={handleClosePopup}></button>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <h1 style={{textAlign: 'center', margin: '0 auto' }}><strong>¡Nueva trivia mensual!</strong></h1> <br/>
                            <img src="/assets/images/micrositio/PLe_PopUp-banner-Jakcpot_2024.png" alt="Descripción de la imagen" style={{ maxWidth: '100%', height: 'auto' }} />
                            <br /><br />
                            <p style={{ fontSize: '20px', textAlign: 'center', fontWeight: '500', color: '#000' }}>
                            <strong>¡Es tiempo de jugar con nuestra trivia del mes de Octubre!</strong>
                                    <br></br><br></br>
                                    Acumula puntos y gana un premio especial. 🎁⚡️
                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <a href="/ilovepanduit">
                            <Button variant="primary" className="rbt-btn btn-gradient rbt-switch-btn" onClick={handlePlayNow}>
                            Juega Ahora
                            </Button>
                        </a>
                    </Modal.Footer>
                </Modal>  */}

                 {/**Pop up de terminos y condiciones */}
                 <Modal className="popup-notificaciones" show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
                    <Modal.Body>
                    <div dangerouslySetInnerHTML={{ __html: datosTerminos.contenido }} />
                    <br/><br/>
                    <div style={{ textAlign: 'center' }}>
                        <a className="rbt-btn btn-gradient rbt-switch-btn rbt-switch-y" href="https://pl-electrico.panduitlatam.com/assets/PLe-TyC_2024.pdf" target="_blank">
                            <span data-text="Descargar PDF">Descarga Términos y Condiciones</span>
                        </a>
                    </div>
                    </Modal.Body>

                    
                    <Modal.Footer>
                        <div className="row w-100">
                            <div className="col-2">
                            <Button variant="secondary" className="rbt-btn rbt-switch-btn" onClick={handleClose}>
                                Cerrar
                            </Button>
                            </div>
                            <div className="col-10 d-flex justify-content-end">
                            <Form.Check
                                className="me-4 mt-4"
                                type={'checkbox'}
                                id={`default-checkbox`}
                                label={`He leido y acepto los términos y condiciones`}
                                onChange={handleCheckboxChange}
                            />

                            <Button variant="primary" className="rbt-btn btn-gradient rbt-switch-btn" onClick={handleAccept} disabled={!isChecked}>
                                Aceptar
                            </Button>
                            </div>
                        </div>
                    </Modal.Footer>
                    </Modal>
                
            </div>
  )
    }
  
}

export default Home